<template>
    <div> 
        <div class="card-container"> <!-- Gildan G5000 -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="../assets/gildan.gif" class="img-fluid" />
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">G5000</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/Gildan_500-01.png" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <p class="card-text">Gildan Adult 5.3 Ounce Ultra Cotton&reg; T-shirt</p>
                                    <ul>
                                        <li>50/50 cotton/polyester blend.</li>
                                        <li>Seamless rib at neck.</li>
                                        <li>Taped shoulder to shoulder</li>
                                        <li>Quarter-turned body.</li>
                                        <li>Double needle stitching throughout.</li>
                                    </ul>
                                    <p>Tax applicable for OH, MD</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <h3>GRAVEL (w/ left chest and/or full front w/ Black Ink)</h3>
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">Small (<span class="sm-price">Salesman price: {{ item1.sPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.sScottQty' @keyup='calculateItem(1)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.sAdvQty' @keyup='calculateItem(1)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.sPalQty' @keyup='calculateItem(1)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Medium (<span class="sm-price">Salesman price: {{ item1.mPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.mScottQty' @keyup='calculateItem(1)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.mAdvQty' @keyup='calculateItem(1)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.mPalQty' @keyup='calculateItem(1)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Large (<span class="sm-price">Salesman price: {{ item1.lPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.lScottQty' @keyup='calculateItem(1)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.lAdvQty' @keyup='calculateItem(1)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.lPalQty' @keyup='calculateItem(1)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">XLarge (<span class="sm-price">Salesman price: {{ item1.xLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.xLScottQty' @keyup='calculateItem(1)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.xLAdvQty' @keyup='calculateItem(1)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.xLPalQty' @keyup='calculateItem(1)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">2XLarge (<span class="sm-price">Salesman price: {{ item1.xxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.xxLScottQty' @keyup='calculateItem(1)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.xxLAdvQty' @keyup='calculateItem(1)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.xxLPalQty' @keyup='calculateItem(1)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">3XLarge (<span class="sm-price">Salesman price: {{ item1.xxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.xxxLScottQty' @keyup='calculateItem(1)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.xxxLAdvQty' @keyup='calculateItem(1)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.xxxLPalQty' @keyup='calculateItem(1)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">4XLarge (<span class="sm-price">Salesman price: {{ item1.xxxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.xxxxLScottQty' @keyup='calculateItem(1)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.xxxxLAdvQty' @keyup='calculateItem(1)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.xxxxLPalQty' @keyup='calculateItem(1)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">5XLarge (<span class="sm-price">Salesman price: {{ item1.xxxxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.xxxxxLScottQty' @keyup='calculateItem(1)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.xxxxxLAdvQty' @keyup='calculateItem(1)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item1.xxxxxLPalQty' @keyup='calculateItem(1)' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br />
                            <h3>SAFETY ORANGE (Black ink, left chest and/or full front)</h3>
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">Small (<span class="sm-price">Salesman price: {{ item2.sPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.sScottQty' @keyup='calculateItem(2)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.sAdvQty' @keyup='calculateItem(2)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.sPalQty' @keyup='calculateItem(2)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Medium (<span class="sm-price">Salesman price: {{ item2.mPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.mScottQty' @keyup='calculateItem(2)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.mAdvQty' @keyup='calculateItem(2)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.mPalQty' @keyup='calculateItem(2)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Large (<span class="sm-price">Salesman price: {{ item2.lPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.lScottQty' @keyup='calculateItem(2)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.lAdvQty' @keyup='calculateItem(2)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.lPalQty' @keyup='calculateItem(2)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">XLarge (<span class="sm-price">Salesman price: {{ item2.xLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.xLScottQty' @keyup='calculateItem(2)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.xLAdvQty' @keyup='calculateItem(2)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.xLPalQty' @keyup='calculateItem(2)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">2XLarge (<span class="sm-price">Salesman price: {{ item2.xxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.xxLScottQty' @keyup='calculateItem(2)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.xxLAdvQty' @keyup='calculateItem(2)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.xxLPalQty' @keyup='calculateItem(2)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">3XLarge (<span class="sm-price">Salesman price: {{ item2.xxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.xxxLScottQty' @keyup='calculateItem(2)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.xxxLAdvQty' @keyup='calculateItem(2)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.xxxLPalQty' @keyup='calculateItem(2)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">4XLarge (<span class="sm-price">Salesman price: {{ item2.xxxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.xxxxLScottQty' @keyup='calculateItem(2)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.xxxxLAdvQty' @keyup='calculateItem(2)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.xxxxLPalQty' @keyup='calculateItem(2)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">5XLarge (<span class="sm-price">Salesman price: {{ item2.xxxxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.xxxxxLScottQty' @keyup='calculateItem(2)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.xxxxxLAdvQty' @keyup='calculateItem(2)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item2.xxxxxLPalQty' @keyup='calculateItem(2)' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ (item1.subtotalScott + item2.subtotalScott) | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ (item1.subtotalAdv + item2.subtotalAdv) | currency }} /
                                <strong>Palco Subtotal: </strong>{{ (item1.subtotalPal + item2.subtotalPal) | currency }} 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- Gildan G5400 -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="../assets/gildan.gif" class="img-fluid" />
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">G5400</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/G5400_G2400-01.png" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <p class="card-text">Gildan Adult 6.0 Ounce Ultra Cotton&reg; Long Sleeve T-shirt</p>
                                    <ul>
                                        <li>100% pre-shrunk cotton.</li>
                                        <li>Seamless rib at neck.</li>
                                        <li>Taped shoulder to shoulder</li>
                                        <li>Quarter-turned body.</li>
                                        <li>Double needle stitching throughout.</li>
                                    </ul>
                                    <p>Tax applicable for OH, MD</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <h3>CARDINAL RED (w/ White Imprint, Left Chest and/or full front)</h3>
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">Small (<span class="sm-price">Salesman price: {{ item3.sPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.sScottQty' @keyup='calculateItem(3)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.sAdvQty' @keyup='calculateItem(3)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.sPalQty' @keyup='calculateItem(3)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Medium (<span class="sm-price">Salesman price: {{ item3.mPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.mScottQty' @keyup='calculateItem(3)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.mAdvQty' @keyup='calculateItem(3)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.mPalQty' @keyup='calculateItem(3)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Large (<span class="sm-price">Salesman price: {{ item3.lPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.lScottQty' @keyup='calculateItem(3)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.lAdvQty' @keyup='calculateItem(3)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.lPalQty' @keyup='calculateItem(3)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">XLarge (<span class="sm-price">Salesman price: {{ item3.xLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.xLScottQty' @keyup='calculateItem(3)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.xLAdvQty' @keyup='calculateItem(3)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.xLPalQty' @keyup='calculateItem(3)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">2XLarge (<span class="sm-price">Salesman price: {{ item3.xxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.xxLScottQty' @keyup='calculateItem(3)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.xxLAdvQty' @keyup='calculateItem(3)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.xxLPalQty' @keyup='calculateItem(3)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">3XLarge (<span class="sm-price">Salesman price: {{ item3.xxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.xxxLScottQty' @keyup='calculateItem(3)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.xxxLAdvQty' @keyup='calculateItem(3)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.xxxLPalQty' @keyup='calculateItem(3)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">4XLarge (<span class="sm-price">Salesman price: {{ item3.xxxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.xxxxLScottQty' @keyup='calculateItem(3)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.xxxxLAdvQty' @keyup='calculateItem(3)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.xxxxLPalQty' @keyup='calculateItem(3)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">5XLarge (<span class="sm-price">Salesman price: {{ item3.xxxxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.xxxxxLScottQty' @keyup='calculateItem(3)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.xxxxxLAdvQty' @keyup='calculateItem(3)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item3.xxxxxLPalQty' @keyup='calculateItem(3)' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ item3.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item3.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item3.subtotalPal | currency }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- Gildan G18000 -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="../assets/gildan.gif" class="img-fluid" />
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">G18000</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/G18000-01.png" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <p class="card-text">Gildan Heavy Blend 8 Ounce Adult Crewneck Sweatshirt</p>
                                    <ul>
                                        <li>Adult Crewneck Sweatshirt 8 oz.</li>
                                        <li>Quarter-turned body.</li>
                                        <li>1x1 rib with spandex.</li>
                                        <li>Double-needle throughout.</li>
                                        <li>50% cotton/50% polyester fleece knit.</li>
                                    </ul>
                                    <p>Tax applicable for OH, MD</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <h3>ROYAL (w/ Black Imprint Left Chest and/or full front)</h3>
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">Small (<span class="sm-price">Salesman price: {{ item4.sPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.sScottQty' @keyup='calculateItem(4)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.sAdvQty' @keyup='calculateItem(4)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.sPalQty' @keyup='calculateItem(4)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Medium (<span class="sm-price">Salesman price: {{ item4.mPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.mScottQty' @keyup='calculateItem(4)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.mAdvQty' @keyup='calculateItem(4)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.mPalQty' @keyup='calculateItem(4)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Large (<span class="sm-price">Salesman price: {{ item4.lPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.lScottQty' @keyup='calculateItem(4)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.lAdvQty' @keyup='calculateItem(4)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.lPalQty' @keyup='calculateItem(4)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">XLarge (<span class="sm-price">Salesman price: {{ item4.xLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.xLScottQty' @keyup='calculateItem(4)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.xLAdvQty' @keyup='calculateItem(4)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.xLPalQty' @keyup='calculateItem(4)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">2XLarge (<span class="sm-price">Salesman price: {{ item4.xxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.xxLScottQty' @keyup='calculateItem(4)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.xxLAdvQty' @keyup='calculateItem(4)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.xxLPalQty' @keyup='calculateItem(4)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">3XLarge (<span class="sm-price">Salesman price: {{ item4.xxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.xxxLScottQty' @keyup='calculateItem(4)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.xxxLAdvQty' @keyup='calculateItem(4)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.xxxLPalQty' @keyup='calculateItem(4)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">4XLarge (<span class="sm-price">Salesman price: {{ item4.xxxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.xxxxLScottQty' @keyup='calculateItem(4)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.xxxxLAdvQty' @keyup='calculateItem(4)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.xxxxLPalQty' @keyup='calculateItem(4)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">5XLarge (<span class="sm-price">Salesman price: {{ item4.xxxxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.xxxxxLScottQty' @keyup='calculateItem(4)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.xxxxxLAdvQty' @keyup='calculateItem(4)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item4.xxxxxLPalQty' @keyup='calculateItem(4)' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ item4.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item4.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item4.subtotalPal | currency }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- Gildan G18500 -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="../assets/gildan.gif" class="img-fluid" />
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">G18500</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/G18500-01.png" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <p class="card-text">Gildan 8 Ounce Adult Hooded Sweatshirt</p>
                                    <ul>
                                        <li>8oz., 50/50 preshrunk cotton/polyester</li>
                                        <li>Classic fit</li>
                                        <li>Air jet yarn for softer feel and reduced pilling</li>
                                        <li>Double-lined hood w/ color-matched drawcord</li>
                                        <li>Double-needle stitching at shoulders, armholes, neck, waistband and cuffs</li>
                                        <li>Double-needle pouch pocket for added reinforcement</li>
                                    </ul>
                                    <p>Tax applicable for OH, MD</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <h3>SAND (w/ black imprint on left chest and/or full front)</h3>
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">Small (<span class="sm-price">Salesman price: {{ item5.sPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.sScottQty' @keyup='calculateItem(5)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.sAdvQty' @keyup='calculateItem(5)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.sPalQty' @keyup='calculateItem(5)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Medium (<span class="sm-price">Salesman price: {{ item5.mPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.mScottQty' @keyup='calculateItem(5)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.mAdvQty' @keyup='calculateItem(5)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.mPalQty' @keyup='calculateItem(5)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Large (<span class="sm-price">Salesman price: {{ item5.lPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.lScottQty' @keyup='calculateItem(5)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.lAdvQty' @keyup='calculateItem(5)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.lPalQty' @keyup='calculateItem(5)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">XLarge (<span class="sm-price">Salesman price: {{ item5.xLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.xLScottQty' @keyup='calculateItem(5)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.xLAdvQty' @keyup='calculateItem(5)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.xLPalQty' @keyup='calculateItem(5)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">2XLarge (<span class="sm-price">Salesman price: {{ item5.xxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.xxLScottQty' @keyup='calculateItem(5)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.xxLAdvQty' @keyup='calculateItem(5)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.xxLPalQty' @keyup='calculateItem(5)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">3XLarge (<span class="sm-price">Salesman price: {{ item5.xxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.xxxLScottQty' @keyup='calculateItem(5)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.xxxLAdvQty' @keyup='calculateItem(5)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.xxxLPalQty' @keyup='calculateItem(5)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">4XLarge (<span class="sm-price">Salesman price: {{ item5.xxxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.xxxxLScottQty' @keyup='calculateItem(5)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.xxxxLAdvQty' @keyup='calculateItem(5)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.xxxxLPalQty' @keyup='calculateItem(5)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">5XLarge (<span class="sm-price">Salesman price: {{ item5.xxxxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.xxxxxLScottQty' @keyup='calculateItem(5)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.xxxxxLAdvQty' @keyup='calculateItem(5)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item5.xxxxxLPalQty' @keyup='calculateItem(5)' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ item5.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item5.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item5.subtotalPal | currency }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- Gildan G5300 -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">GILDAN G5300</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/G5300-01.png" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <p class="card-text">Gildan Adult 6.0 Ounce Ultra Cottton&reg; Short Sleeve Pocket T-shirt</p>
                                    <ul>
                                        <li>100% cotton</li>
                                        <li>Heavyweight fabric</li>
                                        <li>Classic fit</li>
                                        <li>7/8" rib collar</li>
                                        <li>Double-needle pocket, sleeves and bottom hems</li>
                                        <li>Taped neck and shoulders</li>
                                        <li>Left chest pocket</li>
                                        <li>Tear away label</li>
                                    </ul>
                                    <p>Tax applicable for OH, MD</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <h3>FOREST GREEN (w/ white imprint, right chest)</h3>
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">Small (<span class="sm-price">Salesman price: {{ item6.sPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.sScottQty' @keyup='calculateItem(6)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.sAdvQty' @keyup='calculateItem(6)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.sPalQty' @keyup='calculateItem(6)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Medium (<span class="sm-price">Salesman price: {{ item6.mPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.mScottQty' @keyup='calculateItem(6)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.mAdvQty' @keyup='calculateItem(6)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.mPalQty' @keyup='calculateItem(6)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Large (<span class="sm-price">Salesman price: {{ item6.lPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.lScottQty' @keyup='calculateItem(6)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.lAdvQty' @keyup='calculateItem(6)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.lPalQty' @keyup='calculateItem(6)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">XLarge (<span class="sm-price">Salesman price: {{ item6.xLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.xLScottQty' @keyup='calculateItem(6)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.xLAdvQty' @keyup='calculateItem(6)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.xLPalQty' @keyup='calculateItem(6)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">2XLarge (<span class="sm-price">Salesman price: {{ item6.xxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.xxLScottQty' @keyup='calculateItem(6)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.xxLAdvQty' @keyup='calculateItem(6)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.xxLPalQty' @keyup='calculateItem(6)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">3XLarge (<span class="sm-price">Salesman price: {{ item6.xxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.xxxLScottQty' @keyup='calculateItem(6)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.xxxLAdvQty' @keyup='calculateItem(6)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.xxxLPalQty' @keyup='calculateItem(6)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">4XLarge (<span class="sm-price">Salesman price: {{ item6.xxxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.xxxxLScottQty' @keyup='calculateItem(6)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.xxxxLAdvQty' @keyup='calculateItem(6)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.xxxxLPalQty' @keyup='calculateItem(6)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">5XLarge (<span class="sm-price">Salesman price: {{ item6.xxxxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.xxxxxLScottQty' @keyup='calculateItem(6)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.xxxxxLAdvQty' @keyup='calculateItem(6)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item6.xxxxxLPalQty' @keyup='calculateItem(6)' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ item6.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item6.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item6.subtotalPal | currency }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- Tultex 240 -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            &nbsp;
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">TULTEX 240</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/TULTEX_240-01.png" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <p class="card-text">TULTEX 240</p>
                                    <ul>
                                        <li>Heat-set to minimize shrinkage</li>
                                        <li>Slim fit</li>
                                        <li>Self-fabric collar</li>
                                        <li>Back neck tape</li>
                                        <li>Side seams</li>
                                        <li>Double-needle stitching on sleeves and bottom hem</li>
                                    </ul>
                                    <p>Tax applicable for OH, MD</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <h3>HEATHER ATHLETIC BLUE (w/ White Imprint, left chest)</h3>
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">Extra Small (<span class="sm-price">Salesman price: {{ item7.xSPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.xSScottQty' @keyup='calculateItem(7)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.xSAdvQty' @keyup='calculateItem(7)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.xSPalQty' @keyup='calculateItem(7)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Small (<span class="sm-price">Salesman price: {{ item7.sPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.sScottQty' @keyup='calculateItem(7)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.sAdvQty' @keyup='calculateItem(7)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.sPalQty' @keyup='calculateItem(7)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Medium (<span class="sm-price">Salesman price: {{ item7.mPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.mScottQty' @keyup='calculateItem(7)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.mAdvQty' @keyup='calculateItem(7)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.mPalQty' @keyup='calculateItem(7)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Large (<span class="sm-price">Salesman price: {{ item7.lPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.lScottQty' @keyup='calculateItem(7)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.lAdvQty' @keyup='calculateItem(7)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.lPalQty' @keyup='calculateItem(7)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">XLarge (<span class="sm-price">Salesman price: {{ item7.xLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.xLScottQty' @keyup='calculateItem(7)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.xLAdvQty' @keyup='calculateItem(7)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.xLPalQty' @keyup='calculateItem(7)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">2XLarge (<span class="sm-price">Salesman price: {{ item7.xxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.xxLScottQty' @keyup='calculateItem(7)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.xxLAdvQty' @keyup='calculateItem(7)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.xxLPalQty' @keyup='calculateItem(7)' /></td>
                                    </tr>
                                    <!--<tr>
                                        <td><p class="tabletop-pad">3XLarge (<span class="sm-price">Salesman price: {{ item7.xxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.xxxLScottQty' @keyup='calculateItem(7)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.xxxLAdvQty' @keyup='calculateItem(7)' /></td>
                                    </tr>-->
                                    <!--<tr>
                                        <td><p class="tabletop-pad">4XLarge (<span class="sm-price">Salesman price: {{ item7.xxxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item7.xxxxLQty' @keyup='calculateItem(7)' /></td>
                                    </tr>-->
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ item7.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item7.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item7.subtotalPal | currency }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- LAT 3816 -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            &nbsp;
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">LAT 3816</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/LAT3816-01.png" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <p class="card-text">LAT 3816</p>
                                    <ul>
                                        <li>Ribbed collar</li>
                                        <li>Self-fabric back neck tape</li>
                                        <li>Double-needle sleeves and bottom hem</li>
                                    </ul>
                                    <p>Tax applicable for OH, MD</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <h3>SALTWATER (w/ white ink, left chest)</h3>
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">14/16 (<span class="sm-price">Salesman price: {{ item8.sPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item8.sScottQty' @keyup='calculateItem(8)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item8.sAdvQty' @keyup='calculateItem(8)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item8.sPalQty' @keyup='calculateItem(8)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">18/20 (<span class="sm-price">Salesman price: {{ item8.mPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item8.mScottQty' @keyup='calculateItem(8)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item8.mAdvQty' @keyup='calculateItem(8)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item8.mPalQty' @keyup='calculateItem(8)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">22/24 (<span class="sm-price">Salesman price: {{ item8.lPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item8.lScottQty' @keyup='calculateItem(8)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item8.lAdvQty' @keyup='calculateItem(8)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item8.lPalQty' @keyup='calculateItem(8)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">26/28 (<span class="sm-price">Salesman price: {{ item8.xLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item8.xLScottQty' @keyup='calculateItem(8)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item8.xLAdvQty' @keyup='calculateItem(8)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item8.xLPalQty' @keyup='calculateItem(8)' /></td>
                                    </tr>
                                </tbody>
                            </table>

                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ item8.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item8.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item8.subtotalPal | currency }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- Carhartt Duck Vest, CTV01 -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            &nbsp;
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">Carhartt Duck Vest, CTV01</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/CTV01-01.png" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <p class="card-text">A great extra layer, Carhartt's Duck Vest is made of cotton duck and insulated for warmth.</p>
                                    <ul>
                                        <li>12-ounce, firm hand, 100% ring spun cotton duck canvas</li>
                                        <li>Water-repellent and wind-resistant</li>
                                        <li>Two large insulated lower-front pockets</li>
                                        <li>Relaxed fit</li>
                                    </ul>
                                    <p>Tax applicable for OH, MD</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <h3>CARHARTT BROWN (Patch is chestnut/black, Left chest.)</h3>
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">Small (<span class="sm-price">Salesman price: {{ item11.sPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.sScottQty' @keyup='calculateItem(11)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.sAdvQty' @keyup='calculateItem(11)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.sPalQty' @keyup='calculateItem(11)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Medium (<span class="sm-price">Salesman price: {{ item11.mPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.mScottQty' @keyup='calculateItem(11)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.mAdvQty' @keyup='calculateItem(11)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.mPalQty' @keyup='calculateItem(11)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Large (<span class="sm-price">Salesman price: {{ item11.lPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.lScottQty' @keyup='calculateItem(11)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.lAdvQty' @keyup='calculateItem(11)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.lPalQty' @keyup='calculateItem(11)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">XLarge (<span class="sm-price">Salesman price: {{ item11.xLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.xLScottQty' @keyup='calculateItem(11)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.xLAdvQty' @keyup='calculateItem(11)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.xLPalQty' @keyup='calculateItem(11)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">2XLarge (<span class="sm-price">Salesman price: {{ item11.xxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.xxLScottQty' @keyup='calculateItem(11)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.xxLAdvQty' @keyup='calculateItem(11)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.xxLPalQty' @keyup='calculateItem(11)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">3XLarge (<span class="sm-price">Salesman price: {{ item11.xxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.xxxLScottQty' @keyup='calculateItem(11)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.xxxLAdvQty' @keyup='calculateItem(11)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.xxxLPalQty' @keyup='calculateItem(11)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">4XLarge (<span class="sm-price">Salesman price: {{ item11.xxxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.xxxxLScottQty' @keyup='calculateItem(11)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.xxxxLAdvQty' @keyup='calculateItem(11)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.xxxxLPalQty' @keyup='calculateItem(11)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">5XLarge (<span class="sm-price">Salesman price: {{ item11.xxxxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.xxxxxLScottQty' @keyup='calculateItem(11)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.xxxxxLAdvQty' @keyup='calculateItem(11)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item11.xxxxxLPalQty' @keyup='calculateItem(11)' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ item11.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item11.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item11.subtotalPal | currency }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- Big Accessories BX002/BX020 -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            &nbsp;
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">Big Accessories BX002/BX020</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/BX020-01.png" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <p class="card-text">Big Accessories BX002/BX020</p>
                                    <ul>
                                        <li>Maroon w/logo in White Thread, embroidered front, right panel</li>
                                    </ul>
                                    <p>Tax applicable for OH, MD</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <h3>Maroon Cap w/ white thread.</h3>
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">1 size (<span class="sm-price">Salesman price: {{ item10.price | currency }}/each</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item10.qtyScott' @keyup='calculateItem(10)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item10.qtyAdv' @keyup='calculateItem(10)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item10.qtyPal' @keyup='calculateItem(10)' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ item10.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item10.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item10.subtotalPal | currency }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- Nike Sphere Performance Cap -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            <!--<img src="../assets/adidas.png" class="img-fluid" />-->
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">Nike NKFD9709 Sphere Performance Cap</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/NKFD9709-01.png" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <p class="card-text">Nike NKFD9709 Sphere Performance Cap</p>
                                    <p>Tax applicable for OH, MD</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <h3>Black/Gym Red w/ Red Thread, embroidered front, right panel</h3>
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">1 size (<span class="sm-price">Salesman price: {{ item12.price | currency }}/each</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item12.qtyScott' @keyup='calculateItem(12)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item12.qtyAdv' @keyup='calculateItem(12)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item12.qtyPal' @keyup='calculateItem(12)' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ item12.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item12.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item12.subtotalPal | currency }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- DRI DUCK - Running Buck Cap - 3301  -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            &nbsp;
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">DRI DUCK - Running Buck Cap - 3301</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/3301-01_new.png" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <p class="card-text">DRI DUCK - Running Buck Cap - 3301</p>
                                    <ul>
                                        <li>Olive (Embroidered. Olive Drab Thread)</li>
                                    </ul>
                                    <p>Tax applicable for OH, MD</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <h3>Olive Cap</h3>
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">1 size (<span class="sm-price">Salesman price: {{ item19.price | currency }}/each</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item19.qtyScott' @keyup='calculateItem(19)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item19.qtyAdv' @keyup='calculateItem(19)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item19.qtyPal' @keyup='calculateItem(19)' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ item19.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item19.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item19.subtotalPal | currency }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- Columbia 183681 PFG Fish Flag Mesh Flexfit Caps -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            &nbsp;
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">Columbia 183681 PFG Fish Flag Mesh Flexfit Cap</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/183681.png" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <p>Tax applicable for OH, MD</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <h3>Night Tide/Fish Flag(Red Thread - PMS 193C, Embroidered, Right Side Panel)</h3>
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">1 size (<span class="sm-price">Salesman price: {{ item20.price | currency }}/each</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item20.qtyScott' @keyup='calculateItem(20)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item20.qtyAdv' @keyup='calculateItem(20)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item20.qtyPal' @keyup='calculateItem(20)' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;">
                                <strong>Scott Electric Subtotal: </strong>{{ item20.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item20.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item20.subtotalPal | currency }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- SP12FL -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            &nbsp;
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">SP12FL</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/SP12FL-01.png" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <p class="card-text">Navy w/logo in white thread, centered on front cuff</p>
                                    <p>Tax applicable for OH, MD</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <h3>Navy</h3>
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">1 size (<span class="sm-price">Salesman price: {{ item13.price | currency }}/each</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item13.qtyScott' @keyup='calculateItem(13)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item13.qtyAdv' @keyup='calculateItem(13)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item13.qtyPal' @keyup='calculateItem(13)' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ item13.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item13.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item13.subtotalPal | currency }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- Richardson 146R Waffle Cuffed Beanie -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            &nbsp;
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">Richardson 146R Waffle Cuffed Beanie</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/146R-01.png" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <p>Tax applicable for OH, MD</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <h3>Rust (Embroidered w/ White Thread. Center Front, on Cuff)</h3>
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">1 size (<span class="sm-price">Salesman price: {{ item27.price | currency }}/each</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item27.qtyScott' @keyup='calculateItem(27)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item27.qtyAdv' @keyup='calculateItem(27)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item27.qtyPal' @keyup='calculateItem(27)' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ item27.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item27.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item27.subtotalPal | currency }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- Core 88181 Men's Origin Performance Pique Short Sleeve Polo -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            &nbsp;
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">Core 88181 Men's Origin Performance Pique Short Sleeve Polo</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/88181-01.png" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <ul>
                                        <li>Columbia Blue (w/ White Thread, embroidered)</li>
                                    </ul>
                                    <p>Tax applicable for OH, MD</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <h3>Columbia Blue (w/ White Thread, embroidered)</h3>
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">Small (<span class="sm-price">Salesman price: {{ item14.sPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.sScottQty' @keyup='calculateItem(14)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.sAdvQty' @keyup='calculateItem(14)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.sPalQty' @keyup='calculateItem(14)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Medium (<span class="sm-price">Salesman price: {{ item14.mPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.mScottQty' @keyup='calculateItem(14)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.mAdvQty' @keyup='calculateItem(14)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.mPalQty' @keyup='calculateItem(14)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Large (<span class="sm-price">Salesman price: {{ item14.lPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.lScottQty' @keyup='calculateItem(14)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.lAdvQty' @keyup='calculateItem(14)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.lPalQty' @keyup='calculateItem(14)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">XLarge (<span class="sm-price">Salesman price: {{ item14.xLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.xLScottQty' @keyup='calculateItem(14)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.xLAdvQty' @keyup='calculateItem(14)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.xLPalQty' @keyup='calculateItem(14)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">2XLarge (<span class="sm-price">Salesman price: {{ item14.xxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.xxLScottQty' @keyup='calculateItem(14)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.xxLAdvQty' @keyup='calculateItem(14)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.xxLPalQty' @keyup='calculateItem(14)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">3XLarge (<span class="sm-price">Salesman price: {{ item14.xxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.xxxLScottQty' @keyup='calculateItem(14)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.xxxLAdvQty' @keyup='calculateItem(14)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.xxxLPalQty' @keyup='calculateItem(14)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">4XLarge (<span class="sm-price">Salesman price: {{ item14.xxxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.xxxxLScottQty' @keyup='calculateItem(14)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.xxxxLAdvQty' @keyup='calculateItem(14)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item14.xxxxLPalQty' @keyup='calculateItem(14)' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ item14.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item14.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item14.subtotalPal | currency }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- Under Armour 1376844 -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            &nbsp;
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">Under Armour 1376844 Men's Team Tech Quarter-Zip</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/UNDERARMOR_1376844-01.png" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <ul>
                                        <li>4.69 oz., 100% recycled polyester</li>
                                        <li>Material wicks sweat and dries quickly</li>
                                        <li>Anti-odor technology prevents the growth of odor-causing microbes</li>
                                    </ul>
                                    <p>Tax applicable for OH, MD</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">Small (<span class="sm-price">Salesman price: {{ item15.sPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.sScottQty' @keyup='calculateItem(15)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.sAdvQty' @keyup='calculateItem(15)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.sPalQty' @keyup='calculateItem(15)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Medium (<span class="sm-price">Salesman price: {{ item15.mPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.mScottQty' @keyup='calculateItem(15)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.mAdvQty' @keyup='calculateItem(15)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.mPalQty' @keyup='calculateItem(15)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">Large (<span class="sm-price">Salesman price: {{ item15.lPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.lScottQty' @keyup='calculateItem(15)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.lAdvQty' @keyup='calculateItem(15)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.lPalQty' @keyup='calculateItem(15)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">XLarge (<span class="sm-price">Salesman price: {{ item15.xLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.xLScottQty' @keyup='calculateItem(15)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.xLAdvQty' @keyup='calculateItem(15)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.xLPalQty' @keyup='calculateItem(15)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">2XLarge (<span class="sm-price">Salesman price: {{ item15.xxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.xxLScottQty' @keyup='calculateItem(15)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.xxLAdvQty' @keyup='calculateItem(15)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.xxLPalQty' @keyup='calculateItem(15)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">3XLarge (<span class="sm-price">Salesman price: {{ item15.xxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.xxxLScottQty' @keyup='calculateItem(15)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.xxxLAdvQty' @keyup='calculateItem(15)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.xxxLPalQty' @keyup='calculateItem(15)' /></td>
                                    </tr>
                                    <tr>
                                        <td><p class="tabletop-pad">4XLarge (<span class="sm-price">Salesman price: {{ item15.xxxxLPrice | currency }}</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.xxxxLScottQty' @keyup='calculateItem(15)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.xxxxLAdvQty' @keyup='calculateItem(15)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item15.xxxxLPalQty' @keyup='calculateItem(15)' /></td>
                                    </tr>
 
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ item15.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item15.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item15.subtotalPal | currency }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- Nail Apron -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            &nbsp;
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">NAIL APRON (Natural w/ Black ink)</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/23/apron.jpg" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <p class="card-text">Nail Apron</p>
                                    <ul>
                                        <li>10 oz. natural cotton nail apron.</li>
                                    </ul>
                                    <p>Tax applicable for OH, MD</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">1 size (<span class="sm-price">Salesman price: {{ item17.price | currency }}/each</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item17.qtyScott' @keyup='calculateItem(17)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item17.qtyAdv' @keyup='calculateItem(17)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item17.qtyPal' @keyup='calculateItem(17)' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ item17.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item17.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item17.subtotalPal | currency }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- Chocolate Bar -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            &nbsp;
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">Wilson 2 oz. Wunder Plain Chocolate Candy Bar</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/wrapper.jpg" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <p class="card-text">"Festive Christmas" wrapper from "SE"</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">1 size (<span class="sm-price">Salesman price: {{ item18.price | currency }}/each</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item18.qtyScott' @keyup='calculateItem(18)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item18.qtyAdv' @keyup='calculateItem(18)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item18.qtyPal' @keyup='calculateItem(18)' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ item18.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item18.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item18.subtotalPal | currency }}

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- Carhartt CTC0001607 -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            &nbsp;
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">Carhartt CTC0001607 Nylon Duck Organizer</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/CARHARTT_ORGANIZER-01.png" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <p class="card-text">+ Tax</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <h3>Black w/ Foxy Red (PMS 186C) &amp; White for SE &amp; Palco.  All Gold (PMS 1235C) for Advantage Contractor</h3>
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">1 size (<span class="sm-price">Salesman price: {{ item21.price | currency }}/each</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item21.qtyScott' @keyup='calculateItem(21)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item21.qtyAdv' @keyup='calculateItem(21)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item21.qtyPal' @keyup='calculateItem(21)' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ item21.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item21.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item21.subtotalPal | currency }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- Port Authority PT44 -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            &nbsp;
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">Port Authority PT44 Value Beach Towel</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/PT44.png" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <p class="card-text">+ Tax</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <h3>Graphite w/ Foxy Red (PMS 186C) &amp; White for SE &amp; Palco.  All Gold (PMS 1235C) for Advantage Contractor. (Embroidered, Bottom, Front Pocket)</h3>
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">1 size (<span class="sm-price">Salesman price: {{ item22.price | currency }}/each</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item22.qtyScott' @keyup='calculateItem(22)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item22.qtyAdv' @keyup='calculateItem(22)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item22.qtyPal' @keyup='calculateItem(22)' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ item22.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item22.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item22.subtotalPal | currency }} 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- Glass America 304: 8oz Wine Glasses -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            &nbsp;
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">Glass America 304: 8oz Wine Glasses</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/304_WINE_GLASS-01.png" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <p class="card-text">+ Tax</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <h3>Clear (Screened Side 1.  SE &amp; Palco - PMS 186C Red &amp; Black.  Advantage Contractor - PMS 1235C Gold &amp; Black.)</h3>
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">1 size (<span class="sm-price">Salesman price: {{ item23.price | currency }}/each dozen</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item23.qtyScott' @keyup='calculateItem(23)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item23.qtyAdv' @keyup='calculateItem(23)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item23.qtyPal' @keyup='calculateItem(23)' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ item23.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item23.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item23.subtotalPal | currency }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-container"> <!-- Port Authority BP75 Packable Travel Blanket -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            &nbsp;
                        </div>
                        <div class="col-md-9">
                            <h1 class="card-title">Port Authority BP75 Packable Travel Blanket </h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 product">
                            <div class="row">
                                <div class="col">
                                    <img src="../assets/24/BP75.png" class="img-fluid" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="margin-top: 30px;">
                                    <p class="card-text">+ Tax</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 offset-md-1 product-form">
                            <h3>Deep Smoke (Embroidered Front Pocket.  SE &amp; Palco - Foxy Red Thread PMS 186C Red &amp; White.  Advantage Contractor - PMS 1235C Gold Thread)</h3>
                            <hr />
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="col-2" style="text-align: right;">Size</th>
                                        <th class="col-3">Scott Electric Qty</th>
                                        <th class="col-3">Advantage Contractor Qty</th>
                                        <th class="col-2">Palco Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p class="tabletop-pad">1 size (<span class="sm-price">Salesman price: {{ item24.price | currency }}/each</span>):</p></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item24.qtyScott' @keyup='calculateItem(24)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item24.qtyAdv' @keyup='calculateItem(24)' /></td>
                                        <td><input type="text" class="form-control form-pad" placeholder="Qty" v-model='item24.qtyPal' @keyup='calculateItem(24)' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style="margin-top: 30px; text-align: center; color: yellow;"><strong>Scott Electric Subtotal: </strong>{{ item24.subtotalScott | currency }} / 
                                <strong>Advantage Contractor Subtotal: </strong>{{ item24.subtotalAdv | currency }} / 
                                <strong>Palco Subtotal: </strong>{{ item24.subtotalPal | currency }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--<div class="card-container">
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-5 white-text">
                            <h3 class="card-title">Available Screened/Engraved Imprints for the Following Items:</h3>
                            <ul>
                                <li>Gildan 5000</li>
                                <li>Gildan 5400</li>
                                <li>Gildan 18000</li>
                                <li>Jerzees 29P</li>
                                <li>Gildan 18500</li>
                                <li>Under Armour 1305775</li>
                                <li>Gildan 2400</li>
                                <li>District DT7501</li>
                                <li>BIC 65323</li>
                                <li>Nail Apron</li>
                                <li>Valucap 8869</li>
                                <li>Carhartt CT89121325</li>
                                <li>Glass America 15700</li>
                                <li>BIC APAB1A</li>
                                <li>Primeline G904</li>
                                <li>Primeline LG-9320</li>
                                <li>Glass America 522</li>
                                <li>Code Five 3980, Russell LR054V</li>
                                <li>Badger 4209</li>
                            </ul>
                            <div class="row">
                                <div class="col-sm-3">
                                    <img src="../assets/new-se-screened.jpg" class="img-fluid" />
                                </div>
                                <div class="col-sm-3">
                                    <img src="../assets/new-seny-screened.jpg" class="img-fluid" />                                    
                                </div>
                                <div class="col-sm-3">
                                    <img src="../assets/adv.jpg" class="img-fluid" />                                    
                                </div>
                                <div class="col-sm-3">
                                    <img src="../assets/rental-new.jpg" class="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5 offset-md-2 white-text">
                            <h3 class="card-title">Available Embroidery for the Following Items:</h3>
                            <ul>
                                <li>Harrington M990/M990W</li>
                                <li>Kati LC5M</li>
                                <li>Adidas A605</li>
                                <li>Sportsmen SP08</li>
                                <li>ST690</li>
                            </ul>
                            <div class="row">
                                <div class="col-sm-3">
                                    <img src="../assets/new-se.jpg" class="img-fluid" />
                                </div>
                                <div class="col-sm-3">
                                    <img src="../assets/new-seny.jpg" class="img-fluid" />                                    
                                </div>
                                <div class="col-sm-3">
                                    <img src="../assets/adv.jpg" class="img-fluid" />                                    
                                </div>
                                <div class="col-sm-3">
                                    <img src="../assets/rental-new.jpg" class="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  -->


        <!--<div class="card-container">
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 white-text">
                            <center>
                                <h3>Disclaimer</h3>
                            </center>
                            <ul style="text-align: left;">
                                <li>Garment styles are subject to change pending on stock availability.  All attempts will be made to get as close to the style/color shown.</li>
                                <li>Pricing may be higher than shown depending on individual salesman participation in selling of Printables products throughout the year.  Required Printables sales dollar amounts along w/ percentages of pricing increases will be listed in the body of the e-mail being sent out to all salesmen/managers, not in this Disclaimer.</li>
                                <li>Safety green short sleeve tees will be a mix of the old SE logo in 1-color and the new SE logo in 2-colors.  Printables will be using up already printed tees with the old logo before imprinting the new SE logo if needed to finish filling salesmen orders.</li>
                                <li>Advantage Contractor &amp; Advantage Rental logos are 1-color designs only.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->

        <div class="card-container" v-show='alerts.length > 0'> <!-- Error Alerts -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div v-for='(alert, index) in alerts' :key='alert.msg' :class='alert.type' role="alert">
                        <div class="row">
                            <div class="col-11">{{ alert.msg }}</div>
                            <div class="col-1">
                                <button type="button" class="btn btn-light" data-dismiss="alert" aria-label="close" @click='closeAlert(index)'>
                                    <span aria-hidden="true">&times;</span>    
                                </button> 
                            </div>   
                        </div>
                    </div> 
                </div>    
            </div> 
        </div>  

        <div class="card-container" style="margin-bottom: 200px;"> <!-- Checkout Info -->
            <div class="card border border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <form>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label class="card-title" for="name"><strong>Name</strong></label>
                                            <input id="name" type="text" class="form-control" placeholder="Name" v-model='contact.name' required />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group mb-3">
                                            <label class="card-title" for="branch"><strong>Branch</strong></label>
                                            <input id="branch" type="text" class="form-control" placeholder="Branch" v-model='contact.branch' required />
                                        </div>   
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group mb-3">
                                            <label class="card-title" for="email"><strong>E-mail</strong></label>
                                            <input id="email" type="text" class="form-control" placeholder="Name" v-model='contact.email' required />
                                        </div>                                        
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group mb-3">
                                            <label class="card-title" for="phone"><strong>Phone</strong></label>
                                            <input id="phone" type="text" class="form-control" placeholder="Name" v-model='contact.phone' required />
                                        </div>                                        
                                    </div>
                                    <!--<div class="col-md-3">
                                        <label class="card-title" for="ir"><strong>*Imprint Request</strong></label>
                                        <select id="ir" class="form-control" v-model='contact.imprintRequest'>
                                            <option value="">Select an imprint request</option>
                                            <option value="Scott Electric">Scott Electric</option>
                                            <option value="Scott Electric Supply">Scott Electric Supply</option>
                                            <option value="Advantage Contractor">Advantage Contractor</option>
                                            <option value="Advantage Rental">Advantage Rental</option>
                                        </select>
                                    </div>-->
                                </div>
                                <div class="row">
                                    <div class="col-6 offset-3 grand-total">
                                        <p><strong>Grand Total:</strong> {{ grandTotal | currency }}</p>
                                        <p>(+ tax where applicable)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 offset-3">
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label card-title" for="eSig">Electronic Signature</label>
                                            <input class="form-check-input" type="checkbox" id="eSig" v-model='eSignature' />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 offset-3">
                                        <button class="btn btn-primary" type="submit" @click='submit' :disabled='isDisabled'>{{ submitText }}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>        

    </div>
</template>

<script>
/** Disclaimer: 
 *  Everything here was created and written exclusively by Bob Kelecava 
 * (who is the sole developer and creator of the Scott Electric website, online ordering system, and online admin tools) 
 * while employed by Scott Electric as an in-house system developer/programmer */ 
import Api from '@/services/Api.service.js';

export default {
    name: 'OrderForm',
    data () {
        return {
            isDisabled: false,
            submitText: 'Submit',
            alerts: [],
            contact: {
                name: null,
                branch: null,
                email: null,
                phone: null,
                imprintRequest: ''
            },
            eSignature: false, 
            grandTotal: 0,
            item1: { // G5000 (Gravel)
                description: 'Gildan G5000 (Gravel)',
                sPrice: 2.35,
                sScottQty: null,
                sAdvQty: null,
                sPalQty: null,
                mPrice: 2.35,
                mScottQty: null,
                mAdvQty: null,
                mPalQty: null,
                lPrice: 2.35,
                lScottQty: null,
                lAdvQty: null,
                lPalQty: null,
                xLPrice: 2.35,
                xLScottQty: null,
                xLAdvQty: null,
                xLPalQty: null,
                xxLPrice: 3.50,
                xxLScottQty: null,
                xxLAdvQty: null,
                xxLPalQty: null,
                xxxLPrice: 4.00,
                xxxLScottQty: null,
                xxxLAdvQty: null,
                xxxLPalQty: null,
                xxxxLPrice: 4.00,
                xxxxLScottQty: null,
                xxxxLAdvQty: null,
                xxxxLPalQty: null,
                xxxxxLPrice: 4.00,
                xxxxxLScottQty: null,
                xxxxxLAdvQty: null,
                xxxxxLPalQty: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item2: { // G5000 (Safety Orange)
                description: 'Gildan G5000 (Safety Orange)',
                sPrice: 2.35,
                sScottQty: null,
                sAdvQty: null,
                sPalQty: null,
                mPrice: 2.35,
                mScottQty: null,
                mAdvQty: null,
                mPalQty: null,
                lPrice: 2.35,
                lScottQty: null,
                lAdvQty: null,
                lPalQty: null,
                xLPrice: 2.35,
                xLScottQty: null,
                xLAdvQty: null,
                xLPalQty: null,
                xxLPrice: 3.50,
                xxLScottQty: null,
                xxLAdvQty: null,
                xxLPalQty: null,
                xxxLPrice: 4.00,
                xxxLScottQty: null,
                xxxLAdvQty: null,
                xxxLPalQty: null,
                xxxxLPrice: 4.00,
                xxxxLScottQty: null,
                xxxxLAdvQty: null,
                xxxxLPalQty: null,
                xxxxxLPrice: 4.00,
                xxxxxLScottQty: null,
                xxxxxLAdvQty: null,
                xxxxxLPalQty: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item3: { // G5400 (Cardinal Red)
                description: 'Gildan G5400 (Cardinal Red)',
                sPrice: 4.15,
                sScottQty: null,
                sAdvQty: null,
                sPalQty: null,
                mPrice: 4.15,
                mScottQty: null,
                mAdvQty: null,
                mPalQty: null,
                lPrice: 4.15,
                lScottQty: null,
                lAdvQty: null,
                lPalQty: null,
                xLPrice: 4.15,
                xLScottQty: null,
                xLAdvQty: null,
                xLPalQty: null,
                xxLPrice: 4.85,
                xxLScottQty: null,
                xxLAdvQty: null,
                xxLPalQty: null,
                xxxLPrice: 6.15,
                xxxLScottQty: null,
                xxxLAdvQty: null,
                xxxLPalQty: null,
                xxxxLPrice: 6.15,
                xxxxLScottQty: null,
                xxxxLAdvQty: null,
                xxxxLPalQty: null,
                xxxxxLPrice: 6.15,
                xxxxxLScottQty: null,
                xxxxxLAdvQty: null,
                xxxxxLPalQty: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item4: { // G18000 (Royal)
                description: 'Gildan G18000 (Royal)',
                sPrice: 5.30,
                sScottQty: null,
                sAdvQty: null,
                sPalQty: null,
                mPrice: 5.30,
                mScottQty: null,
                mAdvQty: null,
                mPalQty: null,
                lPrice: 5.30,
                lScottQty: null,
                lAdvQty: null,
                lPalQty: null,
                xLPrice: 5.30,
                xLScottQty: null,
                xLAdvQty: null,
                xLPalQty: null,
                xxLPrice: 6.80,
                xxLScottQty: null,
                xxLAdvQty: null,
                xxLPalQty: null,
                xxxLPrice: 7.75,
                xxxLScottQty: null,
                xxxLAdvQty: null,
                xxxLPalQty: null,
                xxxxLPrice: 7.75,
                xxxxLScottQty: null,
                xxxxLAdvQty: null,
                xxxxLPalQty: null,
                xxxxxLPrice: 7.75,
                xxxxxLScottQty: null,
                xxxxxLAdvQty: null,
                xxxxxLPalQty: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item5: { // G18500 (Sand)
                description: 'Gildan G18500 (Sand)',
                sPrice: 6.60,
                sScottQty: null,
                sAdvQty: null,
                sPalQty: null,
                mPrice: 6.60,
                mScottQty: null,
                mAdvQty: null,
                mPalQty: null,
                lPrice: 6.60,
                lScottQty: null,
                lAdvQty: null,
                lPalQty: null,
                xLPrice: 6.60,
                xLScottQty: null,
                xLAdvQty: null,
                xLPalQty: null,
                xxLPrice: 8.40,
                xxLScottQty: null,
                xxLAdvQty: null,
                xxLPalQty: null,
                xxxLPrice: 9.15,
                xxxLScottQty: null,
                xxxLAdvQty: null,
                xxxLPalQty: null,
                xxxxLPrice: 9.15,
                xxxxLScottQty: null,
                xxxxLAdvQty: null,
                xxxxLPalQty: null,
                xxxxxLPrice: 9.15,
                xxxxxLScottQty: null,
                xxxxxLAdvQty: null,
                xxxxxLPalQty: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item6: { // Gildan G5300
                description: 'Gildan G5300 (Forest Green)',
                sPrice: 3.05,
                sScottQty: null,
                sAdvQty: null,
                sPalQty: null,
                mPrice: 3.05,
                mScottQty: null,
                mAdvQty: null,
                mPalQty: null,
                lPrice: 3.05,
                lScottQty: null,
                lAdvQty: null,
                lPalQty: null,
                xLPrice: 3.05,
                xLScottQty: null,
                xLAdvQty: null,
                xLPalQty: null,
                xxLPrice: 3.75,
                xxLScottQty: null,
                xxLAdvQty: null,
                xxLPalQty: null,
                xxxLPrice: 3.98,
                xxxLScottQty: null,
                xxxLAdvQty: null,
                xxxLPalQty: null,
                xxxxLPrice: 6.15,
                xxxxLScottQty: null,
                xxxxLAdvQty: null,
                xxxxLPalQty: null,
                xxxxxLPrice: 6.15,
                xxxxxLScottQty: null,
                xxxxxLAdvQty: null,
                xxxxxLPalQty: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item7: { // Tultex 240
                description: 'Tultex 240 (Heather Athletic Blue)',
                xSPrice: 2.70,
                xSScottQty: null,
                xSAdvQty: null,
                xSPalQty: null,
                sPrice: 2.70,
                sScottQty: null,
                sAdvQty: null,
                sPalQty: null,
                mPrice: 2.70,
                mScottQty: null,
                mAdvQty: null,
                mPalQty: null,
                lPrice: 2.70,
                lScottQty: null,
                lAdvQty: null,
                lPalQty: null,
                xLPrice: 2.70,
                xLScottQty: null,
                xLAdvQty: null,
                xLPalQty: null,
                xxLPrice: 3.70,
                xxLScottQty: null,
                xxLAdvQty: null,
                xxLPalQty: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item8: { // LAT 3816
                description: 'LAT 3816 (Saltwater)',
                sPrice: 4.10,
                sScottQty: null,
                sAdvQty: null,
                sPalQty: null,
                mPrice: 4.10,
                mScottQty: null,
                mAdvQty: null,
                mPalQty: null,
                lPrice: 4.10,
                lScottQty: null,
                lAdvQty: null,
                lPalQty: null,
                xLPrice: 4.10,
                xLScottQty: null,
                xLAdvQty: null,
                xLPalQty: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item11: { // Vest, CTV01
                description: 'Vest, CTV01',
                sPrice: 30.95,
                sScottQty: null,
                sAdvQty: null,
                sPalQty: null,
                mPrice: 30.95,
                mScottQty: null,
                mAdvQty: null,
                mPalQty: null,
                lPrice: 30.95,
                lScottQty: null,
                lAdvQty: null,
                lPalQty: null,
                xLPrice: 30.95,
                xLScottQty: null,
                xLAdvQty: null,
                xLPalQty: null,
                xxLPrice: 30.95,
                xxLScottQty: null,
                xxLAdvQty: null,
                xxLPalQty: null,
                xxxLPrice: 34.55,
                xxxLScottQty: null,
                xxxLAdvQty: null,
                xxxLPalQty: null,
                xxxxLPrice: 34.55,
                xxxxLScottQty: null,
                xxxxLAdvQty: null,
                xxxxLPalQty: null,
                xxxxxLPrice: 34.55,
                xxxxxLScottQty: null,
                xxxxxLAdvQty: null,
                xxxxxLPalQty: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item10: { // Big Accessories BX002/BX020
                description: 'Big Accessories BX002/BX020',
                price: 3.60,
                qtyScott: null,
                qtyAdv: null,
                qtyPal: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item12: { // Nike NKFD9709 Cap
                description: 'Nike NKFD9709 Sphere Cap',
                price: 10.45,
                qtyScott: null,
                qtyAdv: null,
                qtyPal: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item19: { // DRI-DUCK 3301, Running Buck Cap
                description: 'DRI-DUCK 3301, Running Buck Cap',
                price: 6.30,
                qtyScott: null,
                qtyAdv: null,
                qtyPal: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item20: { // Columbia 183681 Cap
                description: 'Columbia 183681 Cap',
                price: 11.65,
                qtyScott: null,
                qtyAdv: null,
                qtyPal: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item13: { // SP12FL (Navy)
                description: 'SP12FL (Navy)',
                price: 3.85,
                qtyScott: null,
                qtyAdv: null,
                qtyPal: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item27: { // Richardson 146R Waffle Cuffed Beanie
                description: 'Richardson 146R Waffle Cuffed Beanie',
                price: 5.15,
                qtyScott: null,
                qtyAdv: null,
                qtyPal: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item14: { // Core 88181 Men's Origin Performance Pique Short Sleeve Polo
                description: 'Core 88181 Men\'s Origin Performance Pique Short Sleeve Polo',
                sPrice: 7.20,
                sScottQty: null,
                sAdvQty: null,
                sPalQty: null,
                mPrice: 7.20,
                mScottQty: null,
                mAdvQty: null,
                mPalQty: null,
                lPrice: 7.20,
                lScottQty: null,
                lAdvQty: null,
                lPalQty: null,
                xLPrice: 7.20,
                xLScottQty: null,
                xLAdvQty: null,
                xLPalQty: null,
                xxLPrice: 7.80,
                xxLScottQty: null,
                xxLAdvQty: null,
                xxLPalQty: null,
                xxxLPrice: 9.00,
                xxxLScottQty: null,
                xxxLAdvQty: null,
                xxxLPalQty: null,
                xxxxLPrice: 9.60,
                xxxxLScottQty: null,
                xxxxLAdvQty: null,
                xxxxLPalQty: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item15: { // Under Armour (1376844)
                description: 'Under Armour (1376844)',
                sPrice: 19.35,
                sScottQty: null,
                sAdvQty: null,
                sPalQty: null,
                mPrice: 19.35,
                mScottQty: null,
                mAdvQty: null,
                mPalQty: null,
                lPrice: 19.35,
                lScottQty: null,
                lAdvQty: null,
                lPalQty: null,
                xLPrice: 19.35,
                xLScottQty: null,
                xLAdvQty: null,
                xLPalQty: null,
                xxLPrice: 21.00,
                xxLScottQty: null,
                xxLAdvQty: null,
                xxLPalQty: null,
                xxxLPrice: 21.99,
                xxxLScottQty: null,
                xxxLAdvQty: null,
                xxxLPalQty: null,
                xxxxLPrice: 23.70,
                xxxxLScottQty: null,
                xxxxLAdvQty: null,
                xxxxLPalQty: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item17: { // Nail Apron (Natural w/ Black ink)
                description: 'Nail Apron (Natural w/ Black ink)',
                price: 1.85,
                qtyScott: null,
                qtyAdv: null,
                qtyPal: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item18: { // Wilson 2 oz. Wunder Plain Chocolate Candy Bar
                description: 'Wilson 2 oz. Wunder Plain Chocolate Candy Bar',
                price: 0.90,
                qtyScott: null,
                qtyAdv: null,
                qtyPal: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item21: { // Carhartt CTC0001607
                description: 'Carhartt CTC0001607',
                price: 19.95,
                qtyScott: null,
                qtyAdv: null,
                qtyPal: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item22: { // Port Authority PT44 Value Beach Towel
                description: 'Port Authority PT44 Value Beach Towel',
                price: 7.95,
                qtyScott: null,
                qtyAdv: null,
                qtyPal: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item23: { // Glass America 304: 8oz Wine Glasses
                description: 'Glass America 304: 8oz Wine Glasses',
                price: 2.25,
                qtyScott: null,
                qtyAdv: null,
                qtyPal: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
            item24: { // Port Authority BP75 Packable Travel Blanket
                description: 'Port Authority BP75 Packable Travel Blanket',
                price: 9.90,
                qtyScott: null,
                qtyAdv: null,
                qtyPal: null,
                subtotalScott: 0,
                subtotalAdv: 0,
                subtotalPal: 0
            },
        }
    },
    filters: {
        currency: function (value) {
        value = value.toFixed(2)
        value+=''
        var x = value.split('.')
        var x1 = x[0]
        var x2 = x.length > 1 ? '.' + x[1] : ''
        var rgx = /(\d+)(\d{3})/
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2')
        }
        var val = x1 + x2
        return '$' + val
        }  
    },
    methods: {
        closeAlert (index) {
            this.alerts.splice(index, 1)
        },
        calculateGrandTotal () {
            this.grandTotal = 
                this.item1.subtotalScott+
                this.item2.subtotalScott+
                this.item3.subtotalScott+
                this.item4.subtotalScott+
                this.item5.subtotalScott+
                this.item6.subtotalScott+
                this.item7.subtotalScott+
                this.item8.subtotalScott+
                this.item11.subtotalScott+
                this.item10.subtotalScott+
                this.item12.subtotalScott+
                this.item19.subtotalScott+
                this.item20.subtotalScott+
                this.item13.subtotalScott+
                this.item27.subtotalScott+
                this.item14.subtotalScott+
                this.item15.subtotalScott+
                this.item17.subtotalScott+
                this.item18.subtotalScott+              
                this.item21.subtotalScott+
                this.item22.subtotalScott+
                this.item23.subtotalScott+
                this.item24.subtotalScott+

                this.item1.subtotalAdv+
                this.item2.subtotalAdv+
                this.item3.subtotalAdv+
                this.item4.subtotalAdv+
                this.item5.subtotalAdv+
                this.item6.subtotalAdv+
                this.item7.subtotalAdv+
                this.item8.subtotalAdv+
                this.item11.subtotalAdv+
                this.item10.subtotalAdv+
                this.item12.subtotalAdv+
                this.item19.subtotalAdv+
                this.item20.subtotalAdv+
                this.item13.subtotalAdv+
                this.item27.subtotalAdv+
                this.item14.subtotalAdv+
                this.item15.subtotalAdv+
                this.item17.subtotalAdv+
                this.item18.subtotalAdv+              
                this.item21.subtotalAdv+
                this.item22.subtotalAdv+
                this.item23.subtotalAdv+
                this.item24.subtotalAdv+

                this.item1.subtotalPal+
                this.item2.subtotalPal+
                this.item3.subtotalPal+
                this.item4.subtotalPal+
                this.item5.subtotalPal+
                this.item6.subtotalPal+
                this.item7.subtotalPal+
                this.item8.subtotalPal+
                this.item11.subtotalPal+
                this.item10.subtotalPal+
                this.item12.subtotalPal+
                this.item19.subtotalPal+
                this.item20.subtotalPal+
                this.item13.subtotalPal+
                this.item27.subtotalPal+
                this.item14.subtotalPal+
                this.item15.subtotalPal+
                this.item17.subtotalPal+
                this.item18.subtotalPal+              
                this.item21.subtotalPal+
                this.item22.subtotalPal+
                this.item23.subtotalPal+
                this.item24.subtotalPal;

        },
        calculateItem (itemIndex) {
            switch (itemIndex) {
                case 1:
                    this.item1.subtotalScott=
                        (this.item1.sPrice*this.item1.sScottQty)+
                        (this.item1.mPrice*this.item1.mScottQty)+
                        (this.item1.lPrice*this.item1.lScottQty)+
                        (this.item1.xLPrice*this.item1.xLScottQty)+
                        (this.item1.xxLPrice*this.item1.xxLScottQty)+
                        (this.item1.xxxLPrice*this.item1.xxxLScottQty)+
                        (this.item1.xxxxLPrice*this.item1.xxxxLScottQty)+
                        (this.item1.xxxxxLPrice*this.item1.xxxxxLScottQty);
                    this.item1.subtotalAdv=
                        (this.item1.sPrice*this.item1.sAdvQty)+
                        (this.item1.mPrice*this.item1.mAdvQty)+
                        (this.item1.lPrice*this.item1.lAdvQty)+
                        (this.item1.xLPrice*this.item1.xLAdvQty)+
                        (this.item1.xxLPrice*this.item1.xxLAdvQty)+
                        (this.item1.xxxLPrice*this.item1.xxxLAdvQty)+
                        (this.item1.xxxxLPrice*this.item1.xxxxLAdvQty)+
                        (this.item1.xxxxxLPrice*this.item1.xxxxxLAdvQty);
                    this.item1.subtotalPal=
                        (this.item1.sPrice*this.item1.sPalQty)+
                        (this.item1.mPrice*this.item1.mPalQty)+
                        (this.item1.lPrice*this.item1.lPalQty)+
                        (this.item1.xLPrice*this.item1.xLPalQty)+
                        (this.item1.xxLPrice*this.item1.xxLPalQty)+
                        (this.item1.xxxLPrice*this.item1.xxxLPalQty)+
                        (this.item1.xxxxLPrice*this.item1.xxxxLPalQty)+
                        (this.item1.xxxxxLPrice*this.item1.xxxxxLPalQty);
                    break;
                case 2:
                    this.item2.subtotalScott=
                        (this.item2.sPrice*this.item2.sScottQty)+
                        (this.item2.mPrice*this.item2.mScottQty)+
                        (this.item2.lPrice*this.item2.lScottQty)+
                        (this.item2.xLPrice*this.item2.xLScottQty)+
                        (this.item2.xxLPrice*this.item2.xxLScottQty)+
                        (this.item2.xxxLPrice*this.item2.xxxLScottQty)+
                        (this.item2.xxxxLPrice*this.item2.xxxxLScottQty)+
                        (this.item2.xxxxxLPrice*this.item2.xxxxxLScottQty);
                    this.item2.subtotalAdv=
                        (this.item2.sPrice*this.item2.sAdvQty)+
                        (this.item2.mPrice*this.item2.mAdvQty)+
                        (this.item2.lPrice*this.item2.lAdvQty)+
                        (this.item2.xLPrice*this.item2.xLAdvQty)+
                        (this.item2.xxLPrice*this.item2.xxLAdvQty)+
                        (this.item2.xxxLPrice*this.item2.xxxLAdvQty)+
                        (this.item2.xxxxLPrice*this.item2.xxxxLAdvQty)+
                        (this.item2.xxxxxLPrice*this.item2.xxxxxLAdvQty);
                    this.item2.subtotalPal=
                        (this.item2.sPrice*this.item2.sPalQty)+
                        (this.item2.mPrice*this.item2.mPalQty)+
                        (this.item2.lPrice*this.item2.lPalQty)+
                        (this.item2.xLPrice*this.item2.xLPalQty)+
                        (this.item2.xxLPrice*this.item2.xxLPalQty)+
                        (this.item2.xxxLPrice*this.item2.xxxLPalQty)+
                        (this.item2.xxxxLPrice*this.item2.xxxxLPalQty)+
                        (this.item2.xxxxxLPrice*this.item2.xxxxxLPalQty);
                    break;
                case 3:
                    this.item3.subtotalScott=
                        (this.item3.sPrice*this.item3.sScottQty)+
                        (this.item3.mPrice*this.item3.mScottQty)+
                        (this.item3.lPrice*this.item3.lScottQty)+
                        (this.item3.xLPrice*this.item3.xLScottQty)+
                        (this.item3.xxLPrice*this.item3.xxLScottQty)+
                        (this.item3.xxxLPrice*this.item3.xxxLScottQty)+
                        (this.item3.xxxxLPrice*this.item3.xxxxLScottQty)+
                        (this.item3.xxxxxLPrice*this.item3.xxxxxLScottQty);
                    this.item3.subtotalAdv=
                        (this.item3.sPrice*this.item3.sAdvQty)+
                        (this.item3.mPrice*this.item3.mAdvQty)+
                        (this.item3.lPrice*this.item3.lAdvQty)+
                        (this.item3.xLPrice*this.item3.xLAdvQty)+
                        (this.item3.xxLPrice*this.item3.xxLAdvQty)+
                        (this.item3.xxxLPrice*this.item3.xxxLAdvQty)+
                        (this.item3.xxxxLPrice*this.item3.xxxxLAdvQty)+
                        (this.item3.xxxxxLPrice*this.item3.xxxxxLAdvQty);
                    this.item3.subtotalPal=
                        (this.item3.sPrice*this.item3.sPalQty)+
                        (this.item3.mPrice*this.item3.mPalQty)+
                        (this.item3.lPrice*this.item3.lPalQty)+
                        (this.item3.xLPrice*this.item3.xLPalQty)+
                        (this.item3.xxLPrice*this.item3.xxLPalQty)+
                        (this.item3.xxxLPrice*this.item3.xxxLPalQty)+
                        (this.item3.xxxxLPrice*this.item3.xxxxLPalQty)+
                        (this.item3.xxxxxLPrice*this.item3.xxxxxLPalQty);
                    break;
                case 4:
                    this.item4.subtotalScott=
                        (this.item4.sPrice*this.item4.sScottQty)+
                        (this.item4.mPrice*this.item4.mScottQty)+
                        (this.item4.lPrice*this.item4.lScottQty)+
                        (this.item4.xLPrice*this.item4.xLScottQty)+
                        (this.item4.xxLPrice*this.item4.xxLScottQty)+
                        (this.item4.xxxLPrice*this.item4.xxxLScottQty)+
                        (this.item4.xxxxLPrice*this.item4.xxxxLScottQty)+
                        (this.item4.xxxxxLPrice*this.item4.xxxxxLScottQty);
                    this.item4.subtotalAdv=
                        (this.item4.sPrice*this.item4.sAdvQty)+
                        (this.item4.mPrice*this.item4.mAdvQty)+
                        (this.item4.lPrice*this.item4.lAdvQty)+
                        (this.item4.xLPrice*this.item4.xLAdvQty)+
                        (this.item4.xxLPrice*this.item4.xxLAdvQty)+
                        (this.item4.xxxLPrice*this.item4.xxxLAdvQty)+
                        (this.item4.xxxxLPrice*this.item4.xxxxLAdvQty)+
                        (this.item4.xxxxxLPrice*this.item4.xxxxxLAdvQty);
                    this.item4.subtotalPal=
                        (this.item4.sPrice*this.item4.sPalQty)+
                        (this.item4.mPrice*this.item4.mPalQty)+
                        (this.item4.lPrice*this.item4.lPalQty)+
                        (this.item4.xLPrice*this.item4.xLPalQty)+
                        (this.item4.xxLPrice*this.item4.xxLPalQty)+
                        (this.item4.xxxLPrice*this.item4.xxxLPalQty)+
                        (this.item4.xxxxLPrice*this.item4.xxxxLPalQty)+
                        (this.item4.xxxxxLPrice*this.item4.xxxxxLPalQty);
                case 5:
                    this.item5.subtotalScott=
                        (this.item5.sPrice*this.item5.sScottQty)+
                        (this.item5.mPrice*this.item5.mScottQty)+
                        (this.item5.lPrice*this.item5.lScottQty)+
                        (this.item5.xLPrice*this.item5.xLScottQty)+
                        (this.item5.xxLPrice*this.item5.xxLScottQty)+
                        (this.item5.xxxLPrice*this.item5.xxxLScottQty)+
                        (this.item5.xxxxLPrice*this.item5.xxxxLScottQty)+
                        (this.item5.xxxxxLPrice*this.item5.xxxxxLScottQty);
                    this.item5.subtotalAdv=
                        (this.item5.sPrice*this.item5.sAdvQty)+
                        (this.item5.mPrice*this.item5.mAdvQty)+
                        (this.item5.lPrice*this.item5.lAdvQty)+
                        (this.item5.xLPrice*this.item5.xLAdvQty)+
                        (this.item5.xxLPrice*this.item5.xxLAdvQty)+
                        (this.item5.xxxLPrice*this.item5.xxxLAdvQty)+
                        (this.item5.xxxxLPrice*this.item5.xxxxLAdvQty)+
                        (this.item5.xxxxxLPrice*this.item5.xxxxxLAdvQty);
                    this.item5.subtotalPal=
                        (this.item5.sPrice*this.item5.sPalQty)+
                        (this.item5.mPrice*this.item5.mPalQty)+
                        (this.item5.lPrice*this.item5.lPalQty)+
                        (this.item5.xLPrice*this.item5.xLPalQty)+
                        (this.item5.xxLPrice*this.item5.xxLPalQty)+
                        (this.item5.xxxLPrice*this.item5.xxxLPalQty)+
                        (this.item5.xxxxLPrice*this.item5.xxxxLPalQty)+
                        (this.item5.xxxxxLPrice*this.item5.xxxxxLPalQty);
                    break;
                case 6:
                    this.item6.subtotalScott=
                        (this.item6.sPrice*this.item6.sScottQty)+
                        (this.item6.mPrice*this.item6.mScottQty)+
                        (this.item6.lPrice*this.item6.lScottQty)+
                        (this.item6.xLPrice*this.item6.xLScottQty)+
                        (this.item6.xxLPrice*this.item6.xxLScottQty)+
                        (this.item6.xxxLPrice*this.item6.xxxLScottQty)+
                        (this.item6.xxxxLPrice*this.item6.xxxxLScottQty)+
                        (this.item6.xxxxxLPrice*this.item6.xxxxxLScottQty);
                    this.item6.subtotalAdv=
                        (this.item6.sPrice*this.item6.sAdvQty)+
                        (this.item6.mPrice*this.item6.mAdvQty)+
                        (this.item6.lPrice*this.item6.lAdvQty)+
                        (this.item6.xLPrice*this.item6.xLAdvQty)+
                        (this.item6.xxLPrice*this.item6.xxLAdvQty)+
                        (this.item6.xxxLPrice*this.item6.xxxLAdvQty)+
                        (this.item6.xxxxLPrice*this.item6.xxxxLAdvQty)+
                        (this.item6.xxxxxLPrice*this.item6.xxxxxLAdvQty);
                    this.item6.subtotalPal=
                        (this.item6.sPrice*this.item6.sPalQty)+
                        (this.item6.mPrice*this.item6.mPalQty)+
                        (this.item6.lPrice*this.item6.lPalQty)+
                        (this.item6.xLPrice*this.item6.xLPalQty)+
                        (this.item6.xxLPrice*this.item6.xxLPalQty)+
                        (this.item6.xxxLPrice*this.item6.xxxLPalQty)+
                        (this.item6.xxxxLPrice*this.item6.xxxxLPalQty)+
                        (this.item6.xxxxxLPrice*this.item6.xxxxxLPalQty);
                case 7:
                    this.item7.subtotalScott=
                        (this.item7.xSPrice*this.item7.xSScottQty)+
                        (this.item7.sPrice*this.item7.sScottQty)+
                        (this.item7.mPrice*this.item7.mScottQty)+
                        (this.item7.lPrice*this.item7.lScottQty)+
                        (this.item7.xLPrice*this.item7.xLScottQty)+
                        (this.item7.xxLPrice*this.item7.xxLScottQty);
                    this.item7.subtotalAdv=
                        (this.item7.xSPrice*this.item7.xSAdvQty)+
                        (this.item7.sPrice*this.item7.sAdvQty)+
                        (this.item7.mPrice*this.item7.mAdvQty)+
                        (this.item7.lPrice*this.item7.lAdvQty)+
                        (this.item7.xLPrice*this.item7.xLAdvQty)+
                        (this.item7.xxLPrice*this.item7.xxLAdvQty);
                    this.item7.subtotalPal=
                        (this.item7.xSPrice*this.item7.xSPalQty)+
                        (this.item7.sPrice*this.item7.sPalQty)+
                        (this.item7.mPrice*this.item7.mPalQty)+
                        (this.item7.lPrice*this.item7.lPalQty)+
                        (this.item7.xLPrice*this.item7.xLPalQty)+
                        (this.item7.xxLPrice*this.item7.xxLPalQty);
                    break;
                case 8:
                    this.item8.subtotalScott=
                        (this.item8.sPrice*this.item8.sScottQty)+
                        (this.item8.mPrice*this.item8.mScottQty)+
                        (this.item8.lPrice*this.item8.lScottQty)+
                        (this.item8.xLPrice*this.item8.xLScottQty);
                    this.item8.subtotalAdv=
                        (this.item8.sPrice*this.item8.sAdvQty)+
                        (this.item8.mPrice*this.item8.mAdvQty)+
                        (this.item8.lPrice*this.item8.lAdvQty)+
                        (this.item8.xLPrice*this.item8.xLAdvQty);
                    this.item8.subtotalPal=
                        (this.item8.sPrice*this.item8.sPalQty)+
                        (this.item8.mPrice*this.item8.mPalQty)+
                        (this.item8.lPrice*this.item8.lPalQty)+
                        (this.item8.xLPrice*this.item8.xLPalQty);
                    break;
                case 11:
                    this.item11.subtotalScott=
                        (this.item11.sPrice*this.item11.sScottQty)+
                        (this.item11.mPrice*this.item11.mScottQty)+
                        (this.item11.lPrice*this.item11.lScottQty)+
                        (this.item11.xLPrice*this.item11.xLScottQty)+
                        (this.item11.xxLPrice*this.item11.xxLScottQty)+
                        (this.item11.xxxLPrice*this.item11.xxxLScottQty)+
                        (this.item11.xxxxLPrice*this.item11.xxxxLScottQty)+
                        (this.item11.xxxxxLPrice*this.item11.xxxxxLScottQty);
                    this.item11.subtotalAdv=
                        (this.item11.sPrice*this.item11.sAdvQty)+
                        (this.item11.mPrice*this.item11.mAdvQty)+
                        (this.item11.lPrice*this.item11.lAdvQty)+
                        (this.item11.xLPrice*this.item11.xLAdvQty)+
                        (this.item11.xxLPrice*this.item11.xxLAdvQty)+
                        (this.item11.xxxLPrice*this.item11.xxxLAdvQty)+
                        (this.item11.xxxxLPrice*this.item11.xxxxLAdvQty)+
                        (this.item11.xxxxxLPrice*this.item11.xxxxxLAdvQty);
                    this.item11.subtotalPal=
                        (this.item11.sPrice*this.item11.sPalQty)+
                        (this.item11.mPrice*this.item11.mPalQty)+
                        (this.item11.lPrice*this.item11.lPalQty)+
                        (this.item11.xLPrice*this.item11.xLPalQty)+
                        (this.item11.xxLPrice*this.item11.xxLPalQty)+
                        (this.item11.xxxLPrice*this.item11.xxxLPalQty)+
                        (this.item11.xxxxLPrice*this.item11.xxxxLPalQty)+
                        (this.item11.xxxxxLPrice*this.item11.xxxxxLPalQty);
                    break;
                case 10:
                    this.item10.subtotalScott=
                        (this.item10.price*this.item10.qtyScott);
                    this.item10.subtotalAdv=
                        (this.item10.price*this.item10.qtyAdv);
                    this.item10.subtotalPal=
                        (this.item10.price*this.item10.qtyPal);
                    break;
                case 12:
                    this.item12.subtotalScott=
                        (this.item12.price*this.item12.qtyScott);
                    this.item12.subtotalAdv=
                        (this.item12.price*this.item12.qtyAdv);
                    this.item12.subtotalPal=
                        (this.item12.price*this.item12.qtyPal);
                    break;
                case 19:
                    this.item19.subtotalScott=
                        (this.item19.price*this.item19.qtyScott);
                    this.item19.subtotalAdv=
                        (this.item19.price*this.item19.qtyAdv);
                    this.item19.subtotalPal=
                        (this.item19.price*this.item19.qtyPal);
                    break;
                case 20:
                    this.item20.subtotalScott=
                        (this.item20.price*this.item20.qtyScott);
                    this.item20.subtotalAdv=
                        (this.item20.price*this.item20.qtyAdv);
                    this.item20.subtotalPal=
                        (this.item20.price*this.item20.qtyPal);
                    break;
                case 13:
                    this.item13.subtotalScott=
                        (this.item13.price*this.item13.qtyScott);
                    this.item13.subtotalAdv=
                        (this.item13.price*this.item13.qtyAdv);
                    this.item13.subtotalPal=
                        (this.item13.price*this.item13.qtyPal);
                    break;
                case 27:
                    this.item27.subtotalScott=
                        (this.item27.price*this.item27.qtyScott);
                    this.item27.subtotalAdv=
                        (this.item27.price*this.item27.qtyAdv);
                    this.item27.subtotalPal=
                        (this.item27.price*this.item27.qtyPal);
                    break;
                case 14:
                    this.item14.subtotalScott=
                        (this.item14.sPrice*this.item14.sScottQty)+
                        (this.item14.mPrice*this.item14.mScottQty)+
                        (this.item14.lPrice*this.item14.lScottQty)+
                        (this.item14.xLPrice*this.item14.xLScottQty)+
                        (this.item14.xxLPrice*this.item14.xxLScottQty)+
                        (this.item14.xxxLPrice*this.item14.xxxLScottQty)+
                        (this.item14.xxxxLPrice*this.item14.xxxxLScottQty);
                    this.item14.subtotalAdv=
                        (this.item14.sPrice*this.item14.sAdvQty)+
                        (this.item14.mPrice*this.item14.mAdvQty)+
                        (this.item14.lPrice*this.item14.lAdvQty)+
                        (this.item14.xLPrice*this.item14.xLAdvQty)+
                        (this.item14.xxLPrice*this.item14.xxLAdvQty)+
                        (this.item14.xxxLPrice*this.item14.xxxLAdvQty)+
                        (this.item14.xxxxLPrice*this.item14.xxxxLAdvQty);
                    this.item14.subtotalPal=
                        (this.item14.sPrice*this.item14.sPalQty)+
                        (this.item14.mPrice*this.item14.mPalQty)+
                        (this.item14.lPrice*this.item14.lPalQty)+
                        (this.item14.xLPrice*this.item14.xLPalQty)+
                        (this.item14.xxLPrice*this.item14.xxLPalQty)+
                        (this.item14.xxxLPrice*this.item14.xxxLPalQty)+
                        (this.item14.xxxxLPrice*this.item14.xxxxLPalQty);
                    break;
                case 15:
                    this.item15.subtotalScott=
                        (this.item15.sPrice*this.item15.sScottQty)+
                        (this.item15.mPrice*this.item15.mScottQty)+
                        (this.item15.lPrice*this.item15.lScottQty)+
                        (this.item15.xLPrice*this.item15.xLScottQty)+
                        (this.item15.xxLPrice*this.item15.xxLScottQty)+
                        (this.item15.xxxLPrice*this.item15.xxxLScottQty)+
                        (this.item15.xxxxLPrice*this.item15.xxxxLScottQty);
                    this.item15.subtotalAdv=
                        (this.item15.sPrice*this.item15.sAdvQty)+
                        (this.item15.mPrice*this.item15.mAdvQty)+
                        (this.item15.lPrice*this.item15.lAdvQty)+
                        (this.item15.xLPrice*this.item15.xLAdvQty)+
                        (this.item15.xxLPrice*this.item15.xxLAdvQty)+
                        (this.item15.xxxLPrice*this.item15.xxxLAdvQty)+
                        (this.item15.xxxxLPrice*this.item15.xxxxLAdvQty);
                    this.item15.subtotalPal=
                        (this.item15.sPrice*this.item15.sPalQty)+
                        (this.item15.mPrice*this.item15.mPalQty)+
                        (this.item15.lPrice*this.item15.lPalQty)+
                        (this.item15.xLPrice*this.item15.xLPalQty)+
                        (this.item15.xxLPrice*this.item15.xxLPalQty)+
                        (this.item15.xxxLPrice*this.item15.xxxLPalQty)+
                        (this.item15.xxxxLPrice*this.item15.xxxxLPalQty);
                    break;
                case 17:
                    this.item17.subtotalScott=
                        (this.item17.price*this.item17.qtyScott);
                    this.item17.subtotalAdv=
                        (this.item17.price*this.item17.qtyAdv);
                    this.item17.subtotalPal=
                        (this.item17.price*this.item17.qtyPal);
                    break;
                case 18:
                    this.item18.subtotalScott=
                        (this.item18.price*this.item18.qtyScott);
                    this.item18.subtotalAdv=
                        (this.item18.price*this.item18.qtyAdv);
                    this.item18.subtotalPal=
                        (this.item18.price*this.item18.qtyPal);
                    break;
                case 21:
                    this.item21.subtotalScott=
                        (this.item21.price*this.item21.qtyScott);
                    this.item21.subtotalAdv=
                        (this.item21.price*this.item21.qtyAdv);
                    this.item21.subtotalPal=
                        (this.item21.price*this.item21.qtyPal);
                    break;
                case 22:
                    this.item22.subtotalScott=
                        (this.item22.price*this.item22.qtyScott);
                    this.item22.subtotalAdv=
                        (this.item22.price*this.item22.qtyAdv);
                    this.item22.subtotalPal=
                        (this.item22.price*this.item22.qtyPal);
                    break;
                case 23:
                    this.item23.subtotalScott=
                        (this.item23.price*this.item23.qtyScott);
                    this.item23.subtotalAdv=
                        (this.item23.price*this.item23.qtyAdv);
                    this.item23.subtotalPal=
                        (this.item23.price*this.item23.qtyPal);
                    break;
                case 24:
                    this.item24.subtotalScott=
                        (this.item24.price*this.item24.qtyScott);
                    this.item24.subtotalAdv=
                        (this.item24.price*this.item24.qtyAdv);
                    this.item24.subtotalPal=
                        (this.item24.price*this.item24.qtyPal);
                    break;
            }
            this.calculateGrandTotal();
        },
        convertToNumber (item) {
            if (!item || item === '') {
                return 0
            } else {
                return parseInt(item)
            }
        },
        async submit (evt) {
            evt.preventDefault()
            if (!this.contact.name || this.contact.name === '') {
                document.getElementById('name').focus()
                return this.alerts.push({ type: 'alert alert-warning alert-dismissible fade show', msg: 'Please enter a valid name' })
            }
            if (!this.contact.branch || this.contact.branch === '') {
                document.getElementById('branch').focus()
                return this.alerts.push({ type: 'alert alert-warning alert-dismissible fade show', msg: 'Please enter your branch' })
            }
            if (!this.contact.email || this.contact.email === '') {
                document.getElementById('email').focus()
                return this.alerts.push({ type: 'alert alert-warning alert-dismissible fade show', msg: 'Please enter a valid e-mail address' })
            }
            if (!this.contact.phone || this.contact.phone === '') {
                document.getElementById('phone').focus()
                return this.alerts.push({ type: 'alert alert-warning alert-dismissible fade show', msg: 'Please enter a valid phone number' })
            }
            /**if (!this.contact.imprintRequest || this.contact.imprintRequest === '') {
                return this.alerts.push({ type: 'alert alert-warning alert-dismissible fade show', msg: 'Please select an imprint request' })
            }**/
            if (this.eSignature === false) {
                return this.alerts.push({ type: 'alert alert-warning alert-dismissible fade show', msg: 'You must confirm this order by checking the Electronic Signature' })
            }
            this.isDisabled = true
            this.submitText = 'Processing'
            // convert quantities to numbers
            this.item1.sScottQty = this.convertToNumber(this.item1.sScottQty)
            this.item1.mScottQty = this.convertToNumber(this.item1.mScottQty)
            this.item1.lScottQty = this.convertToNumber(this.item1.lScottQty)
            this.item1.xLScottQty = this.convertToNumber(this.item1.xLScottQty)
            this.item1.xxLScottQty = this.convertToNumber(this.item1.xxLScottQty)
            this.item1.xxxLScottQty = this.convertToNumber(this.item1.xxxLScottQty)
            this.item1.xxxxLScottQty = this.convertToNumber(this.item1.xxxxLScottQty)
            this.item1.xxxxxLScottQty = this.convertToNumber(this.item1.xxxxxLScottQty)

            this.item2.sScottQty = this.convertToNumber(this.item2.sScottQty)
            this.item2.mScottQty = this.convertToNumber(this.item2.mScottQty)
            this.item2.lScottQty = this.convertToNumber(this.item2.lScottQty)
            this.item2.xLScottQty = this.convertToNumber(this.item2.xLScottQty)
            this.item2.xxLScottQty = this.convertToNumber(this.item2.xxLScottQty)
            this.item2.xxxLScottQty = this.convertToNumber(this.item2.xxxLScottQty)
            this.item2.xxxxLScottQty = this.convertToNumber(this.item2.xxxxLScottQty)
            this.item2.xxxxxLScottQty = this.convertToNumber(this.item2.xxxxxLScottQty)

            this.item3.sScottQty = this.convertToNumber(this.item3.sScottQty)
            this.item3.mScottQty = this.convertToNumber(this.item3.mScottQty)
            this.item3.lScottQty = this.convertToNumber(this.item3.lScottQty)
            this.item3.xLScottQty = this.convertToNumber(this.item3.xLScottQty)
            this.item3.xxLScottQty = this.convertToNumber(this.item3.xxLScottQty)
            this.item3.xxxLScottQty = this.convertToNumber(this.item3.xxxLScottQty)
            this.item3.xxxxLScottQty = this.convertToNumber(this.item3.xxxxLScottQty)
            this.item3.xxxxxLScottQty = this.convertToNumber(this.item3.xxxxxLScottQty)

            this.item4.sScottQty = this.convertToNumber(this.item4.sScottQty)
            this.item4.mScottQty = this.convertToNumber(this.item4.mScottQty)
            this.item4.lScottQty = this.convertToNumber(this.item4.lScottQty)
            this.item4.xLScottQty = this.convertToNumber(this.item4.xLScottQty)
            this.item4.xxLScottQty = this.convertToNumber(this.item4.xxLScottQty)
            this.item4.xxxLScottQty = this.convertToNumber(this.item4.xxxLScottQty)
            this.item4.xxxxLScottQty = this.convertToNumber(this.item4.xxxxLScottQty)
            this.item4.xxxxxLScottQty = this.convertToNumber(this.item4.xxxxxLScottQty)

            this.item5.sScottQty = this.convertToNumber(this.item5.sScottQty)
            this.item5.mScottQty = this.convertToNumber(this.item5.mScottQty)
            this.item5.lScottQty = this.convertToNumber(this.item5.lScottQty)
            this.item5.xLScottQty = this.convertToNumber(this.item5.xLScottQty)
            this.item5.xxLScottQty = this.convertToNumber(this.item5.xxLScottQty)
            this.item5.xxxLScottQty = this.convertToNumber(this.item5.xxxLScottQty)
            this.item5.xxxxLScottQty = this.convertToNumber(this.item5.xxxxLScottQty)
            this.item5.xxxxxLScottQty = this.convertToNumber(this.item5.xxxxxLScottQty)

            this.item6.sScottQty = this.convertToNumber(this.item6.sScottQty)
            this.item6.mScottQty = this.convertToNumber(this.item6.mScottQty)
            this.item6.lScottQty = this.convertToNumber(this.item6.lScottQty)
            this.item6.xLScottQty = this.convertToNumber(this.item6.xLScottQty)
            this.item6.xxLScottQty = this.convertToNumber(this.item6.xxLScottQty)
            this.item6.xxxLScottQty = this.convertToNumber(this.item6.xxxLScottQty)
            this.item6.xxxxLScottQty = this.convertToNumber(this.item6.xxxxLScottQty)
            this.item6.xxxxxLScottQty = this.convertToNumber(this.item6.xxxxxLScottQty)

            this.item7.xSScottQty = this.convertToNumber(this.item7.xSScottQty)
            this.item7.sScottQty = this.convertToNumber(this.item7.sScottQty)
            this.item7.mScottQty = this.convertToNumber(this.item7.mScottQty)
            this.item7.lScottQty = this.convertToNumber(this.item7.lScottQty)
            this.item7.xLScottQty = this.convertToNumber(this.item7.xLScottQty)
            this.item7.xxLScottQty = this.convertToNumber(this.item7.xxLScottQty)

            this.item8.sScottQty = this.convertToNumber(this.item8.sScottQty)
            this.item8.mScottQty = this.convertToNumber(this.item8.mScottQty)
            this.item8.lScottQty = this.convertToNumber(this.item8.lScottQty)
            this.item8.xLScottQty = this.convertToNumber(this.item8.xLScottQty)

            this.item11.sScottQty = this.convertToNumber(this.item11.sScottQty)
            this.item11.mScottQty = this.convertToNumber(this.item11.mScottQty)
            this.item11.lScottQty = this.convertToNumber(this.item11.lScottQty)
            this.item11.xLScottQty = this.convertToNumber(this.item11.xLScottQty)
            this.item11.xxLScottQty = this.convertToNumber(this.item11.xxLScottQty)
            this.item11.xxxLScottQty = this.convertToNumber(this.item11.xxxLScottQty)
            this.item11.xxxxLScottQty = this.convertToNumber(this.item11.xxxxLScottQty)
            this.item11.xxxxxLScottQty = this.convertToNumber(this.item11.xxxxxLScottQty)

            this.item10.qtyScott = this.convertToNumber(this.item10.qtyScott)

            this.item12.qtyScott = this.convertToNumber(this.item12.qtyScott)

            this.item19.qtyScott = this.convertToNumber(this.item19.qtyScott)

            this.item20.qtyScott = this.convertToNumber(this.item20.qtyScott)

            this.item13.qtyScott = this.convertToNumber(this.item13.qtyScott)

            this.item27.qtyScott = this.convertToNumber(this.item27.qtyScott)

            this.item14.sScottQty = this.convertToNumber(this.item14.sScottQty)
            this.item14.mScottQty = this.convertToNumber(this.item14.mScottQty)
            this.item14.lScottQty = this.convertToNumber(this.item14.lScottQty)
            this.item14.xLScottQty = this.convertToNumber(this.item14.xLScottQty)
            this.item14.xxLScottQty = this.convertToNumber(this.item14.xxLScottQty)
            this.item14.xxxLScottQty = this.convertToNumber(this.item14.xxxLScottQty)
            this.item14.xxxxLScottQty = this.convertToNumber(this.item14.xxxxLScottQty)

            this.item15.sScottQty = this.convertToNumber(this.item15.sScottQty)
            this.item15.mScottQty = this.convertToNumber(this.item15.mScottQty)
            this.item15.lScottQty = this.convertToNumber(this.item15.lScottQty)
            this.item15.xLScottQty = this.convertToNumber(this.item15.xLScottQty)
            this.item15.xxLScottQty = this.convertToNumber(this.item15.xxLScottQty)
            this.item15.xxxLScottQty = this.convertToNumber(this.item15.xxxLScottQty)
            this.item15.xxxxLScottQty = this.convertToNumber(this.item15.xxxxLScottQty)

            this.item17.qtyScott = this.convertToNumber(this.item17.qtyScott)

            this.item18.qtyScott = this.convertToNumber(this.item18.qtyScott)

            this.item21.qtyScott = this.convertToNumber(this.item21.qtyScott)

            this.item22.qtyScott = this.convertToNumber(this.item22.qtyScott)

            this.item23.qtyScott = this.convertToNumber(this.item23.qtyScott)

            this.item24.qtyScott = this.convertToNumber(this.item24.qtyScott)

          
            this.item1.sAdvQty = this.convertToNumber(this.item1.sAdvQty)
            this.item1.mAdvQty = this.convertToNumber(this.item1.mAdvQty)
            this.item1.lAdvQty = this.convertToNumber(this.item1.lAdvQty)
            this.item1.xLAdvQty = this.convertToNumber(this.item1.xLAdvQty)
            this.item1.xxLAdvQty = this.convertToNumber(this.item1.xxLAdvQty)
            this.item1.xxxLAdvQty = this.convertToNumber(this.item1.xxxLAdvQty)
            this.item1.xxxxLAdvQty = this.convertToNumber(this.item1.xxxxLAdvQty)
            this.item1.xxxxxLAdvQty = this.convertToNumber(this.item1.xxxxxLAdvQty)

            this.item2.sAdvQty = this.convertToNumber(this.item2.sAdvQty)
            this.item2.mAdvQty = this.convertToNumber(this.item2.mAdvQty)
            this.item2.lAdvQty = this.convertToNumber(this.item2.lAdvQty)
            this.item2.xLAdvQty = this.convertToNumber(this.item2.xLAdvQty)
            this.item2.xxLAdvQty = this.convertToNumber(this.item2.xxLAdvQty)
            this.item2.xxxLAdvQty = this.convertToNumber(this.item2.xxxLAdvQty)
            this.item2.xxxxLAdvQty = this.convertToNumber(this.item2.xxxxLAdvQty)
            this.item2.xxxxxLAdvQty = this.convertToNumber(this.item2.xxxxxLAdvQty)

            this.item3.sAdvQty = this.convertToNumber(this.item3.sAdvQty)
            this.item3.mAdvQty = this.convertToNumber(this.item3.mAdvQty)
            this.item3.lAdvQty = this.convertToNumber(this.item3.lAdvQty)
            this.item3.xLAdvQty = this.convertToNumber(this.item3.xLAdvQty)
            this.item3.xxLAdvQty = this.convertToNumber(this.item3.xxLAdvQty)
            this.item3.xxxLAdvQty = this.convertToNumber(this.item3.xxxLAdvQty)
            this.item3.xxxxLAdvQty = this.convertToNumber(this.item3.xxxxLAdvQty)
            this.item3.xxxxxLAdvQty = this.convertToNumber(this.item3.xxxxxLAdvQty)

            this.item4.sAdvQty = this.convertToNumber(this.item4.sAdvQty)
            this.item4.mAdvQty = this.convertToNumber(this.item4.mAdvQty)
            this.item4.lAdvQty = this.convertToNumber(this.item4.lAdvQty)
            this.item4.xLAdvQty = this.convertToNumber(this.item4.xLAdvQty)
            this.item4.xxLAdvQty = this.convertToNumber(this.item4.xxLAdvQty)
            this.item4.xxxLAdvQty = this.convertToNumber(this.item4.xxxLAdvQty)
            this.item4.xxxxLAdvQty = this.convertToNumber(this.item4.xxxxLAdvQty)
            this.item4.xxxxxLAdvQty = this.convertToNumber(this.item4.xxxxxLAdvQty)

            this.item5.sAdvQty = this.convertToNumber(this.item5.sAdvQty)
            this.item5.mAdvQty = this.convertToNumber(this.item5.mAdvQty)
            this.item5.lAdvQty = this.convertToNumber(this.item5.lAdvQty)
            this.item5.xLAdvQty = this.convertToNumber(this.item5.xLAdvQty)
            this.item5.xxLAdvQty = this.convertToNumber(this.item5.xxLAdvQty)
            this.item5.xxxLAdvQty = this.convertToNumber(this.item5.xxxLAdvQty)
            this.item5.xxxxLAdvQty = this.convertToNumber(this.item5.xxxxLAdvQty)
            this.item5.xxxxxLAdvQty = this.convertToNumber(this.item5.xxxxxLAdvQty)

            this.item6.sAdvQty = this.convertToNumber(this.item6.sAdvQty)
            this.item6.mAdvQty = this.convertToNumber(this.item6.mAdvQty)
            this.item6.lAdvQty = this.convertToNumber(this.item6.lAdvQty)
            this.item6.xLAdvQty = this.convertToNumber(this.item6.xLAdvQty)
            this.item6.xxLAdvQty = this.convertToNumber(this.item6.xxLAdvQty)
            this.item6.xxxLAdvQty = this.convertToNumber(this.item6.xxxLAdvQty)
            this.item6.xxxxLAdvQty = this.convertToNumber(this.item6.xxxxLAdvQty)
            this.item6.xxxxxLAdvQty = this.convertToNumber(this.item6.xxxxxLAdvQty)

            this.item7.xSAdvQty = this.convertToNumber(this.item7.xSAdvQty)
            this.item7.sAdvQty = this.convertToNumber(this.item7.sAdvQty)
            this.item7.mAdvQty = this.convertToNumber(this.item7.mAdvQty)
            this.item7.lAdvQty = this.convertToNumber(this.item7.lAdvQty)
            this.item7.xLAdvQty = this.convertToNumber(this.item7.xLAdvQty)
            this.item7.xxLAdvQty = this.convertToNumber(this.item7.xxLAdvQty)

            this.item8.sAdvQty = this.convertToNumber(this.item8.sAdvQty)
            this.item8.mAdvQty = this.convertToNumber(this.item8.mAdvQty)
            this.item8.lAdvQty = this.convertToNumber(this.item8.lAdvQty)
            this.item8.xLAdvQty = this.convertToNumber(this.item8.xLAdvQty)

            this.item11.sAdvQty = this.convertToNumber(this.item11.sAdvQty)
            this.item11.mAdvQty = this.convertToNumber(this.item11.mAdvQty)
            this.item11.lAdvQty = this.convertToNumber(this.item11.lAdvQty)
            this.item11.xLAdvQty = this.convertToNumber(this.item11.xLAdvQty)
            this.item11.xxLAdvQty = this.convertToNumber(this.item11.xxLAdvQty)
            this.item11.xxxLAdvQty = this.convertToNumber(this.item11.xxxLAdvQty)
            this.item11.xxxxLAdvQty = this.convertToNumber(this.item11.xxxxLAdvQty)
            this.item11.xxxxxLAdvQty = this.convertToNumber(this.item11.xxxxxLAdvQty)

            this.item10.qtyAdv = this.convertToNumber(this.item10.qtyAdv)

            this.item12.qtyAdv = this.convertToNumber(this.item12.qtyAdv)

            this.item19.qtyAdv = this.convertToNumber(this.item19.qtyAdv)

            this.item20.qtyAdv = this.convertToNumber(this.item20.qtyAdv)

            this.item13.qtyAdv = this.convertToNumber(this.item13.qtyAdv)

            this.item27.qtyAdv = this.convertToNumber(this.item27.qtyAdv)

            this.item14.sAdvQty = this.convertToNumber(this.item14.sAdvQty)
            this.item14.mAdvQty = this.convertToNumber(this.item14.mAdvQty)
            this.item14.lAdvQty = this.convertToNumber(this.item14.lAdvQty)
            this.item14.xLAdvQty = this.convertToNumber(this.item14.xLAdvQty)
            this.item14.xxLAdvQty = this.convertToNumber(this.item14.xxLAdvQty)
            this.item14.xxxLAdvQty = this.convertToNumber(this.item14.xxxLAdvQty)
            this.item14.xxxxLAdvQty = this.convertToNumber(this.item14.xxxxLAdvQty)

            this.item15.sAdvQty = this.convertToNumber(this.item15.sAdvQty)
            this.item15.mAdvQty = this.convertToNumber(this.item15.mAdvQty)
            this.item15.lAdvQty = this.convertToNumber(this.item15.lAdvQty)
            this.item15.xLAdvQty = this.convertToNumber(this.item15.xLAdvQty)
            this.item15.xxLAdvQty = this.convertToNumber(this.item15.xxLAdvQty)
            this.item15.xxxLAdvQty = this.convertToNumber(this.item15.xxxLAdvQty)
            this.item15.xxxxLAdvQty = this.convertToNumber(this.item15.xxxxLAdvQty)

            this.item17.qtyAdv = this.convertToNumber(this.item17.qtyAdv)

            this.item18.qtyAdv = this.convertToNumber(this.item18.qtyAdv)

            this.item21.qtyAdv = this.convertToNumber(this.item21.qtyAdv)

            this.item22.qtyAdv = this.convertToNumber(this.item22.qtyAdv)

            this.item23.qtyAdv = this.convertToNumber(this.item23.qtyAdv)

            this.item24.qtyAdv = this.convertToNumber(this.item24.qtyAdv)


            this.item1.sPalQty = this.convertToNumber(this.item1.sPalQty)
            this.item1.mPalQty = this.convertToNumber(this.item1.mPalQty)
            this.item1.lPalQty = this.convertToNumber(this.item1.lPalQty)
            this.item1.xLPalQty = this.convertToNumber(this.item1.xLPalQty)
            this.item1.xxLPalQty = this.convertToNumber(this.item1.xxLPalQty)
            this.item1.xxxLPalQty = this.convertToNumber(this.item1.xxxLPalQty)
            this.item1.xxxxLPalQty = this.convertToNumber(this.item1.xxxxLPalQty)
            this.item1.xxxxxLPalQty = this.convertToNumber(this.item1.xxxxxLPalQty)

            this.item2.sPalQty = this.convertToNumber(this.item2.sPalQty)
            this.item2.mPalQty = this.convertToNumber(this.item2.mPalQty)
            this.item2.lPalQty = this.convertToNumber(this.item2.lPalQty)
            this.item2.xLPalQty = this.convertToNumber(this.item2.xLPalQty)
            this.item2.xxLPalQty = this.convertToNumber(this.item2.xxLPalQty)
            this.item2.xxxLPalQty = this.convertToNumber(this.item2.xxxLPalQty)
            this.item2.xxxxLPalQty = this.convertToNumber(this.item2.xxxxLPalQty)
            this.item2.xxxxxLPalQty = this.convertToNumber(this.item2.xxxxxLPalQty)

            this.item3.sPalQty = this.convertToNumber(this.item3.sPalQty)
            this.item3.mPalQty = this.convertToNumber(this.item3.mPalQty)
            this.item3.lPalQty = this.convertToNumber(this.item3.lPalQty)
            this.item3.xLPalQty = this.convertToNumber(this.item3.xLPalQty)
            this.item3.xxLPalQty = this.convertToNumber(this.item3.xxLPalQty)
            this.item3.xxxLPalQty = this.convertToNumber(this.item3.xxxLPalQty)
            this.item3.xxxxLPalQty = this.convertToNumber(this.item3.xxxxLPalQty)
            this.item3.xxxxxLPalQty = this.convertToNumber(this.item3.xxxxxLPalQty)

            this.item4.sPalQty = this.convertToNumber(this.item4.sPalQty)
            this.item4.mPalQty = this.convertToNumber(this.item4.mPalQty)
            this.item4.lPalQty = this.convertToNumber(this.item4.lPalQty)
            this.item4.xLPalQty = this.convertToNumber(this.item4.xLPalQty)
            this.item4.xxLPalQty = this.convertToNumber(this.item4.xxLPalQty)
            this.item4.xxxLPalQty = this.convertToNumber(this.item4.xxxLPalQty)
            this.item4.xxxxLPalQty = this.convertToNumber(this.item4.xxxxLPalQty)
            this.item4.xxxxxLPalQty = this.convertToNumber(this.item4.xxxxxLPalQty)

            this.item5.sPalQty = this.convertToNumber(this.item5.sPalQty)
            this.item5.mPalQty = this.convertToNumber(this.item5.mPalQty)
            this.item5.lPalQty = this.convertToNumber(this.item5.lPalQty)
            this.item5.xLPalQty = this.convertToNumber(this.item5.xLPalQty)
            this.item5.xxLPalQty = this.convertToNumber(this.item5.xxLPalQty)
            this.item5.xxxLPalQty = this.convertToNumber(this.item5.xxxLPalQty)
            this.item5.xxxxLPalQty = this.convertToNumber(this.item5.xxxxLPalQty)
            this.item5.xxxxxLPalQty = this.convertToNumber(this.item5.xxxxxLPalQty)

            this.item6.sPalQty = this.convertToNumber(this.item6.sPalQty)
            this.item6.mPalQty = this.convertToNumber(this.item6.mPalQty)
            this.item6.lPalQty = this.convertToNumber(this.item6.lPalQty)
            this.item6.xLPalQty = this.convertToNumber(this.item6.xLPalQty)
            this.item6.xxLPalQty = this.convertToNumber(this.item6.xxLPalQty)
            this.item6.xxxLPalQty = this.convertToNumber(this.item6.xxxLPalQty)
            this.item6.xxxxLPalQty = this.convertToNumber(this.item6.xxxxLPalQty)
            this.item6.xxxxxLPalQty = this.convertToNumber(this.item6.xxxxxLPalQty)

            this.item7.xSPalQty = this.convertToNumber(this.item7.xSPalQty)
            this.item7.sPalQty = this.convertToNumber(this.item7.sPalQty)
            this.item7.mPalQty = this.convertToNumber(this.item7.mPalQty)
            this.item7.lPalQty = this.convertToNumber(this.item7.lPalQty)
            this.item7.xLPalQty = this.convertToNumber(this.item7.xLPalQty)
            this.item7.xxLPalQty = this.convertToNumber(this.item7.xxLPalQty)

            this.item8.sPalQty = this.convertToNumber(this.item8.sPalQty)
            this.item8.mPalQty = this.convertToNumber(this.item8.mPalQty)
            this.item8.lPalQty = this.convertToNumber(this.item8.lPalQty)
            this.item8.xLPalQty = this.convertToNumber(this.item8.xLPalQty)

            this.item11.sPalQty = this.convertToNumber(this.item11.sPalQty)
            this.item11.mPalQty = this.convertToNumber(this.item11.mPalQty)
            this.item11.lPalQty = this.convertToNumber(this.item11.lPalQty)
            this.item11.xLPalQty = this.convertToNumber(this.item11.xLPalQty)
            this.item11.xxLPalQty = this.convertToNumber(this.item11.xxLPalQty)
            this.item11.xxxLPalQty = this.convertToNumber(this.item11.xxxLPalQty)
            this.item11.xxxxLPalQty = this.convertToNumber(this.item11.xxxxLPalQty)
            this.item11.xxxxxLPalQty = this.convertToNumber(this.item11.xxxxxLPalQty)

            this.item10.qtyPal = this.convertToNumber(this.item10.qtyPal)

            this.item12.qtyPal = this.convertToNumber(this.item12.qtyPal)

            this.item19.qtyPal = this.convertToNumber(this.item19.qtyPal)

            this.item20.qtyPal = this.convertToNumber(this.item20.qtyPal)

            this.item13.qtyPal = this.convertToNumber(this.item13.qtyPal)

            this.item27.qtyPal = this.convertToNumber(this.item27.qtyPal)

            this.item14.sPalQty = this.convertToNumber(this.item14.sPalQty)
            this.item14.mPalQty = this.convertToNumber(this.item14.mPalQty)
            this.item14.lPalQty = this.convertToNumber(this.item14.lPalQty)
            this.item14.xLPalQty = this.convertToNumber(this.item14.xLPalQty)
            this.item14.xxLPalQty = this.convertToNumber(this.item14.xxLPalQty)
            this.item14.xxxLPalQty = this.convertToNumber(this.item14.xxxLPalQty)
            this.item14.xxxxLPalQty = this.convertToNumber(this.item14.xxxxLPalQty)

            this.item15.sPalQty = this.convertToNumber(this.item15.sPalQty)
            this.item15.mPalQty = this.convertToNumber(this.item15.mPalQty)
            this.item15.lPalQty = this.convertToNumber(this.item15.lPalQty)
            this.item15.xLPalQty = this.convertToNumber(this.item15.xLPalQty)
            this.item15.xxLPalQty = this.convertToNumber(this.item15.xxLPalQty)
            this.item15.xxxLPalQty = this.convertToNumber(this.item15.xxxLPalQty)
            this.item15.xxxxLPalQty = this.convertToNumber(this.item15.xxxxLPalQty)

            this.item17.qtyPal = this.convertToNumber(this.item17.qtyPal)

            this.item18.qtyPal = this.convertToNumber(this.item18.qtyPal)

            this.item21.qtyPal = this.convertToNumber(this.item21.qtyPal)

            this.item22.qtyPal = this.convertToNumber(this.item22.qtyPal)

            this.item23.qtyPal = this.convertToNumber(this.item23.qtyPal)

            this.item24.qtyPal = this.convertToNumber(this.item24.qtyPal)
            const payload = {
                contact: this.contact,
                grandTotal: this.grandTotal,
                item1: this.item1,
                item2: this.item2,
                item3: this.item3,
                item4: this.item4,
                item5: this.item5,
                item6: this.item6,
                item7: this.item7,
                item8: this.item8,
                item11: this.item11,
                item10: this.item10,
                item12: this.item12,
                item19: this.item19,
                item20: this.item20,
                item13: this.item13,
                item27: this.item27,
                item14: this.item14,
                item15: this.item15,
                item17: this.item17,
                item18: this.item18,
                item21: this.item21,
                item22: this.item22,
                item23: this.item23,
                item24: this.item24
            }
            Api.submit(payload)
            .then(res => {
                this.eSignature = false
                if (res.data.success === true) {
                    this.$router.push('/thank-you')
                } else {
                    this.alerts.push({ type: 'alert alert-danger alert-dismissable fade show', msg: 'Something went wrong.  Your order could not be submitted at this time.'}) 
                }
            })
            .catch(err => {
                this.eSignature = false
                this.isDisabled = false
                this.submitText = 'Submit'
                this.alerts.push({ type: 'alert alert-danger alert-dismissable fade show', msg: JSON.stringify(err) })
            })
        }
    }
}
</script>

<style scoped>
.card-container {
    margin-bottom: 30px;
}
.card {
    width: 90%;
    background:rgba(0,0,0,0.4)
}
.card-title {
    color: #fff;
}
.card-text {
    padding-top: 30px;
}
.product {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
}
.product-form {
    color: #fff;
}
.table {
    color: #fff;
    font-size: 1.3em;
}
.sm-price {
    font-size: .7em;
}
.tabletop-pad {
    padding-top: 10px;
    text-align: right;
}
.form-pad {
    margin-top: 12px;
}
.grand-total {
    font-size: 2em;
    color:yellowgreen;
}
.white-text {
    color: #fff;
}
</style>